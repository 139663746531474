import React, { FC, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

interface Props {
  src: string;
  width: string;
  type: string;
}

export const AssetWithPlaceholder: FC<Props> = ({ src, width, type }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading && <Skeleton animation="wave" width={140} height={100} />}
      {type == 'image' && (
        <img
          src={src}
          hidden={isLoading}
          width={width}
          onLoad={() => {
            setIsLoading(false);
          }}
        ></img>
      )}
      {type == 'video' && (
        <video
          width={width}
          hidden={isLoading}
          controls
          src={src}
          onLoadedData={() => {
            setIsLoading(false);
          }}
        ></video>
      )}
    </>
  );
};
