import { ChangeEvent, FC, useState } from 'react';

import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, debounce } from '@mui/material';

import { useGetAllOrganizations } from '../../../api-http/organizations';
import { AlertBar } from '../../../components/AlertBar';
import { PageTitle } from '../../../components/PageTitle';
import { useAuth } from '../../../context/auth-context';
import { UserRole } from '../../../types/user.types';
import { metaTitle } from '../../../util/tabTitle';
import { OrganizationsTable } from './OrganizationsTable';
import { AddOrganizationModal } from './components/AddOrganizationModal';
import { AddUserModal } from '../users/components/AddUserModal';
import { StyleTextfield } from '../../../components/TextField';

const TopContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OrganizationPage: FC = () => {
  metaTitle('Super Admin - Organizations');

  const { currentUser } = useAuth();
  const { error, refetch } = useGetAllOrganizations();

  const [openUserModal, setOpenUserModal] = useState(false);
  const [selectedOrgName, setSelectedOrgName] = useState('');
  const [openCreateOrgModal, setOpenCreateOrgModal] = useState(false);

  if (currentUser && currentUser.role !== UserRole.superAdmin) {
    return <Navigate to="/" />;
  }
  const selectedOrg = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSelectedOrgName(event.target.value);
  };

  return (
    <>
      <PageTitle>Organizations Page (Super Admin)</PageTitle>
      <TopContainer>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateOrgModal(true)}
            style={{ marginRight: 10 }}
          >
            Create organization
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenUserModal(true)}
            disabled={currentUser?.role === 'user'}
          >
            Add user
          </Button>
        </div>
        <StyleTextfield
          sx={{
            '& > :not(style)': {
              m: 1,
              width: '30ch',
              height: '50px',
              paddingLeft: '10px',
              paddingBottom: '2px',
              fontSize: '14px',
            },
          }}
          label="Search Organization"
          variant="outlined"
          onChange={debounce((event) => selectedOrg(event), 200)}
        />
      </TopContainer>

      <AlertBar severity="error" msg={error?.msg} />

      <OrganizationsTable selectedOrgName={selectedOrgName} />

      <AddOrganizationModal
        onClose={() => setOpenCreateOrgModal(false)}
        open={openCreateOrgModal}
        onFinished={() => refetch()}
      />

      <AddUserModal
        onClose={() => setOpenUserModal(false)}
        open={openUserModal}
        onFinished={() => refetch()}
      />
    </>
  );
};

export default OrganizationPage;
