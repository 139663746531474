import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import {
  CategoryOptions,
  PaginateCategoryOptions,
} from '../types/pagination.types';
import { SlideBody } from '../types/slide.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

interface CreatePresentationBody {
  name: string;
  status: boolean;
  slides: SlideWithOptions[];
  category: string;
  organizationId?: string;
}

export interface SlideWithOptions extends Omit<SlideOption, 'slideData'> {
  slideData?: SlideBody;
  id: number;
}

export interface CreatePresentationResponse {
  name: string;
  slides: SlideWithOptions[];
  status: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  category: CategoryOptions;
}

export interface UpdatePresentationResponse {
  _id: string;
}

interface SlideOption {
  slideId: string;
  slideData: string;
  animation: string;
  duration: number;
}

export interface PresentationUpdateBody {
  id: string;
  name?: string;
  slides?: SlideOption[];
  status?: boolean;
  category?: string;
}

export type GetPresentationResponse = CreatePresentationResponse;

export const useCreatePresentation = (
  opt?: MutOptions<CreatePresentationResponse>,
) =>
  useMutation<
    CreatePresentationResponse,
    ApiServiceErr,
    CreatePresentationBody
  >(async (data) => {
    const response = await axiosApi.post('/presentations/create', data);
    return response.data;
  }, opt);

export const useGetPresentation = (id: string) =>
  useQuery<GetPresentationResponse, ApiServiceErr>(
    ['/presentations', id],
    async () => {
      const response = await axiosApi.get(`/presentations/${id}`);
      const presentation = response.data;
      for (const [key, slide] of presentation?.slides?.entries() || []) {
        slide.id = key + 1;
      }
      return presentation;
    },
  );

export const useDeletePresentation = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/presentations/delete/${data.id}`);
    return response.data;
  }, opt);

export const useUpdatePresentation = (
  opt?: MutOptions<UpdatePresentationResponse>,
) =>
  useMutation<
    UpdatePresentationResponse,
    ApiServiceErr,
    PresentationUpdateBody
  >(async (data) => {
    const response = await axiosApi.put(`/presentations/update`, data);
    return response.data;
  }, opt);

export const useGetPaginatePresentation = ({
  limit,
  page,
  sortBy,
  order,
  category,
}: PaginateCategoryOptions) =>
  useQuery<PagingResponse<GetPresentationResponse>, ApiServiceErr>(
    [
      '/presentations/paginate/presentations?limit=${limit}&page=${page}&sortBy=${sortBy}&order=${order}&category=${category}',
      limit,
      page,
      sortBy,
      order,
      category,
    ],
    async () => {
      const response = await axiosApi.get(
        category === ''
          ? `/presentations/paginate/presentations?limit=${limit}&page=${page}&sortby=${sortBy}&order=${order}`
          : `/presentations/paginate/presentations?limit=${limit}&page=${page}&sortby=${sortBy}&order=${order}&category=${category}`,
      );
      return response.data;
    },
  );
