import { GrapeJsEditorOptions } from './types';
import grapesjs from 'grapesjs';
import { Nullable } from '../../types';
const roundToNearest5 = (x: number) => Math.round(x / 5) * 5;

export const changeZoomLevely = (
  editor: GrapeJsEditorOptions,
  zoomBy: number,
) => {
  const zoomValue = roundToNearest5(editor.Canvas.getZoom()) + zoomBy;
  updateZoom(editor, zoomValue);
};

export const updateZoom = (editor: GrapeJsEditorOptions, zoomValue: number) => {
  editor.Panels.getButton('options', 'zoom')?.set(
    'label',
    `${Math.floor(zoomValue)}%`,
  );
  editor.Canvas.setZoom(zoomValue);
};

export const injectEditorOverFlowFix = (editor: Nullable<grapesjs.Editor>) => {
  if (editor) {
    const currentStyles = editor.getCss() as string;
    const removeScrollStyles = 'html{overflow-x:hidden;overflow-y:hidden;}';

    if (!currentStyles.includes(removeScrollStyles)) {
      editor.addStyle(removeScrollStyles);
    }
  }
};

export const resizeToFitToWidth = (editor: GrapeJsEditorOptions) => {
  const deviceType = editor.DeviceManager.getSelected();
  const canvas = document.getElementsByClassName('gjs-cv-canvas');
  const deviceWidth = parseInt(deviceType.attributes.width);

  const width = canvas[0].clientWidth;
  const ratio = (width / deviceWidth) * 100;
  updateZoom(editor, ratio > 100 ? 100 : ratio);
};

interface GetFitToWidthOption {
  originalWidth: number;
  originalHeight: number;
  margin?: number;
}

export const getFitToWidthRatio = ({
  originalWidth,
  originalHeight,
  margin = 0,
}: GetFitToWidthOption) => {
  const { innerWidth } = window;
  const boxWidth = innerWidth - margin;

  const ratio = boxWidth / originalWidth;
  const heightToWidthRatio = originalHeight / originalWidth;

  const boxHeight = boxWidth * heightToWidthRatio;

  return {
    boxHeight,
    boxWidth,
    ratio,
  };
};
