import React, { FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Button, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useAdminResetPassword } from '../../../api-http/superAdmin';
import { AlertBar } from '../../../components/AlertBar';
import { authMsg, notifyMsg } from '../../../configs';
import { Nullable } from '../../../types';
import { BaseUserWithOrg } from '../../../types/user.types';
import { useSnackbar } from 'notistack';
import { ERROR, SUCCESS } from '../../../util/constant';
import { getApiError } from '../../../api-http/apiService';

interface ResetPasswordFormProps {
  onFinished: () => void;
  userData?: Nullable<BaseUserWithOrg>;
}

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .max(255)
    .required(authMsg.NEW_PASSWORD_REQUIRED)
    .min(6, authMsg.PASSWORD_6CHAR_REQUIRED)
    .matches(/^[^\s]+$/, authMsg.PASSWORD_SPACES)
    .test(
      'passwordRequirements',
      authMsg.PASSWORD_COMBINATION_REQUIRED,
      (value: any) =>
        [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
          pattern.test(value),
        ),
    ),

  confirmNewPassword: yup
    .string()
    .max(255)
    .required(authMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('newPassword')], authMsg.PASSWORD_UNMATCHED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onFinished,
  userData,
}) => {
  const { isLoading, error, isSuccess, mutate, isError } =
    useAdminResetPassword();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      onFinished();
      enqueueSnackbar(notifyMsg.PASSWORD_CHANGED_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        newPassword: values?.newPassword,
        id: userData?._id || '',
      });
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id={'newPassword'}
          name={'newPassword'}
          label={'New Password'}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          style={{ marginBottom: 10 }}
          type={'password'}
        />
        <Stack spacing={1}>
          <TextField
            fullWidth
            id={'confirmNewPassword'}
            name={'confirmNewPassword'}
            label={'Confirm Password'}
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            helperText={
              formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword
            }
            style={{ marginBottom: 10 }}
            type={'password'}
          />

          <Button
            color={'primary'}
            disabled={isLoading}
            variant={'contained'}
            fullWidth
            type={'submit'}
          >
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
