import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { ChevronDown } from 'react-feather';

import { Button, LinearProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useGetAdminUsers } from '../../../api-http/superAdmin';
import { useUserDelete } from '../../../api-http/users';
import { DialogModal } from '../../../components';
import { AlertBar } from '../../../components/AlertBar';
import { Nullable } from '../../../types';
import { BaseUserWithOrg } from '../../../types/user.types';
import { displayDate } from '../../../util/dateTime';
import { useUserRoleName } from '../../../util/useUserTypes';
import { useSnackbar } from 'notistack';
import { ResetPasswordForm } from './ResetPasswordForm';
import { UserActionMenu } from './UserActionMenu';
import { UserUpdateForm } from './UserUpdateForm';
import { notifyMsg } from '../../../configs';
import { ERROR, SUCCESS } from '../../../util/constant';

interface UserTableProp {
  selectedOrgId: Nullable<string>;
  selectedUserId: Nullable<string>;
}

export const UserTable: FC<UserTableProp> = ({
  selectedOrgId,
  selectedUserId,
}) => {
  const confirm = useConfirm();
  const { data: adminUsers, isLoading, error, refetch } = useGetAdminUsers();
  const {
    isLoading: isLoadingUserDelete,
    error: errorUserDelete,
    mutate,
    isSuccess,
    isError,
  } = useUserDelete();

  const [activeUser, setActiveUser] = useState<BaseUserWithOrg | undefined>();
  const [renameUserModal, setRenameUserModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userResetPasswordModal, setUserResetPasswordModal] = useState(false);

  useEffect(() => {
    refetch();
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.USER_DELETE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      enqueueSnackbar(notifyMsg.DELETE_ATTEMPT_FAILED, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: BaseUserWithOrg,
  ) => {
    setActiveUser(user);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to delete?',
        confirmationText: 'Yes',
      });

      mutate({ id: activeUser?._id || '' });
    } catch (error) {}
  };

  return (
    <>
      {(isLoading || isLoadingUserDelete) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || errorUserDelete?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>User Name</b>
              </TableCell>
              <TableCell>
                <b>Role</b>
              </TableCell>
              <TableCell align="right">
                <b>Email</b>
              </TableCell>
              <TableCell align="right">
                <b>Updated At</b>
              </TableCell>
              <TableCell align="right">
                <b>Created At</b>
              </TableCell>
              <TableCell align="right">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminUsers
              ?.filter(
                (user) =>
                  !selectedOrgId || selectedOrgId === user?.organization?._id,
              )
              ?.filter(
                (user) => !selectedUserId || selectedUserId === user?._id,
              )
              .map((user) => {
                return (
                  <TableRow
                    key={user._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {useUserRoleName(user.role)}
                    </TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">
                      {displayDate(user.updatedAt)}
                    </TableCell>
                    <TableCell align="right">
                      {displayDate(user.createdAt)}
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={(event) => handleClick(event, user)}>
                        <ChevronDown size={16} />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <UserActionMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClickRename={() => {
          setRenameUserModal(true);
          handleClose();
        }}
        onClickDelete={() => {
          handleDelete();
          handleClose();
        }}
        onClickResetPassword={() => {
          setUserResetPasswordModal(true);
          handleClose();
        }}
      />

      <DialogModal
        onClose={() => setRenameUserModal(false)}
        open={renameUserModal}
        title={'Rename User'}
      >
        <UserUpdateForm
          profileData={activeUser && activeUser}
          onFinished={() => {
            setRenameUserModal(false);
            refetch();
          }}
        />
      </DialogModal>
      <DialogModal
        onClose={() => setUserResetPasswordModal(false)}
        open={userResetPasswordModal}
        title={'Reset password'}
      >
        <ResetPasswordForm
          onFinished={() => setUserResetPasswordModal(false)}
          userData={activeUser}
        />
      </DialogModal>
    </>
  );
};
