import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiServiceErr, axiosApi, MutOptions } from './apiService';
import { axiosDisplayApi } from './displayApiService';
import { DynamicKeyValue, ProjectData } from '../types/slide.types';

interface LoginWithPinCodeBody {
  pincode: string;
}

export interface LoginWithPinCodeResponse {
  authToken: string;
}

interface Slide {
  animation: string;
  duration: number;
  slideData: string;
  slideId: string;
}

export interface GetDisplayPresentationsResponse {
  _id: string;
  name: string;
  slides: Slide[];
  status: boolean;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

interface SlideDataBody {
  _id: string;
  title: string;
  status: boolean;
  projectData: ProjectData;
  dynamicData: DynamicKeyValue[];
  organization: string;
  createdAt: string;
  updatedAt: string;
}

interface SlideData extends Omit<Slide, 'slideId'> {
  slideId: SlideDataBody;
}

export interface GetDisplayPresentationResponse {
  _id: string;
  name: string;
  slides: SlideData[];
  status: boolean;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

export const useLoginWithPinCode = (
  opt?: MutOptions<LoginWithPinCodeResponse>,
) =>
  useMutation<LoginWithPinCodeResponse, ApiServiceErr, LoginWithPinCodeBody>(
    async (data) => {
      const response = await axiosApi.post(
        `/organizations/display-app/login`,
        data,
      );
      return response.data;
    },
    opt,
  );

export const useGetDisplayPresentations = () =>
  useQuery<GetDisplayPresentationsResponse[], ApiServiceErr>(
    ['display-app/auth/presentations'],
    async () => {
      const response = await axiosDisplayApi.get(
        `/presentations/display-app/auth/presentations`,
      );
      return response.data;
    },
  );

export const useGetDisplayPresentation = (id: string) =>
  useQuery<GetDisplayPresentationResponse, ApiServiceErr>(
    ['display-app/auth/{id}', id],
    async () => {
      const response = await axiosDisplayApi.get(
        `/presentations/display-app/auth/${id}`,
      );
      return response.data;
    },
  );
