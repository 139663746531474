import React, { FC, useEffect } from 'react';
import { Key, Eye, Calendar } from 'react-feather';
import { Button, Stack } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  GetPresentationResponse,
  useDeletePresentation,
} from '../../api-http/presentations';
import { useConfirm } from 'material-ui-confirm';
import { displayDateTime } from '../../util/dateTime';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';

const PublishTitle = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const PublishSubTitle = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  margin-left: 3px;
`;

const PublishWrapper = styled.div`
  border: none;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;

interface IPublishBox {
  presentationKey: string;
  presentation?: GetPresentationResponse;
}

const PublishBox: FC<IPublishBox> = ({ presentationKey, presentation }) => {
  const navigate = useNavigate();
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  const { mutate, data } = useDeletePresentation();
  const confirm = useConfirm();

  const onMoveToTrash = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to delete?',
        confirmationText: 'Yes',
      });
      mutate({ id: presentationKey });
    } catch (error) {}
  };

  useEffect(() => {
    if (data) {
      navigate('/presentations');
    }
  }, [data]);

  return (
    <PublishWrapper>
      <PublishTitle>Presentation Info</PublishTitle>
      <Stack gap={2} width="100%">
        <InfoWrapper>
          <Stack gap={2} direction="row">
            <div>
              <Key size={12} />
              <PublishSubTitle>
                Status: {presentation?.status ? 'Published' : 'Draft'}
              </PublishSubTitle>
            </div>
            <div>
              <Eye size={12} />
              <PublishSubTitle>
                Updated: {displayDateTime(presentation?.updatedAt)}
              </PublishSubTitle>
            </div>
            <div>
              <Calendar size={12} />
              <PublishSubTitle>
                Created: {displayDateTime(presentation?.createdAt)}
              </PublishSubTitle>
            </div>
          </Stack>
          <Stack direction="row" gap={2}>
            <Button
              color={'error'}
              variant="outlined"
              onClick={onMoveToTrash}
              disabled={isViewOnly}
            >
              Move to trash
            </Button>
          </Stack>
        </InfoWrapper>
      </Stack>
    </PublishWrapper>
  );
};

export default PublishBox;
