import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../types/user.types';
import { UsersTable } from './UsersTable';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { FilterSelectWrapper } from '../../components/common/FilterSelectWrapper';
import { AddUserModal } from '../admin/users/components/AddUserModal';

const UsersPage: FC = () => {
  metaTitle('Users');
  const [openUserModal, setOpenUserModal] = useState(false);
  const [refreshUserTable, setRefreshUserTable] = useState(false);

  const disableAddUser = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
    UserRole.layoutEditor,
  ]);

  return (
    <>
      <PageTitle>Users</PageTitle>
      <FilterSelectWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenUserModal(true)}
          disabled={disableAddUser}
          style={{ marginBottom: 0 }}
        >
          Add user
        </Button>
      </FilterSelectWrapper>

      <UsersTable refresh={refreshUserTable} />

      <AddUserModal
        onClose={() => setOpenUserModal(false)}
        open={openUserModal}
        onFinished={() => {
          setOpenUserModal(false);
          setRefreshUserTable(!refreshUserTable);
        }}
      />
    </>
  );
};

export default UsersPage;
