import React, { FC } from 'react';
import {
  AppBar as MuiAppBar,
  Grid,
  Hidden,
  Toolbar,
  IconButton as MuiIconButton,
} from '@mui/material';
import styled from 'styled-components/macro';
import { Menu as MenuIcon } from '@mui/icons-material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { useAuth } from '../context/auth-context';
import OrganizationSwitch from './OrganizationSwitcher';

const AppBar = styled(MuiAppBar)`
  background-color: ${(props) => props.theme.sidebar.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
  cursor: pointer;
`;

const Button = styled('span')`
  cursor: pointer;
`;

interface INavBar {
  onDrawerToggle?: React.MouseEventHandler<HTMLButtonElement>;
}

const NavBar: FC<INavBar> = ({ onDrawerToggle }) => {
  const { logout } = useAuth();
  return (
    <React.Fragment>
      <AppBar color="inherit" position="sticky">
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <OrganizationSwitch />
            </Grid>
            <Grid item>
              <Button onClick={() => logout()}>
                <PowerSettingsNewIcon color="primary" />
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default NavBar;
