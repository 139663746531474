import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

import { useGetDisplayPresentation } from '../../api-http/displays';
import { AlertBar } from '../../components/AlertBar';
import { LinearProgress } from '@mui/material';
import { metaTitle } from '../../util/tabTitle';
import PresentationPreview from '../../components/preview/PresentationPreview';
import { WS_URL } from '../../configs';

const socket = io(WS_URL);

const PresentationPreviewPage: FC = () => {
  metaTitle('Preview');

  const params = useParams<{ id: string }>();
  const { data, error, isLoading, refetch } = useGetDisplayPresentation(
    params?.id || '',
  );
  const navigate = useNavigate();

  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    if (!isConnected) {
      socket.connect();
    }
  }, [isConnected]);

  useEffect(() => {
    function handleOnline() {
      refetch();
    }

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  useEffect(() => {
    socket.once('connect', () => {
      setIsConnected(true);
    });

    socket.once('updatePresentation', (updatedId) => {
      if (updatedId === data?._id) {
        refetch();
      }
    });

    socket.once('deletePresentation', (deletedId) => {
      if (deletedId === data?._id) {
        navigate('/display-list');
      }
    });

    socket.once('updateslide', (updatedPresentationsList) => {
      if (
        updatedPresentationsList &&
        updatedPresentationsList.includes(data?._id)
      ) {
        refetch();
      }
    });

    socket.once('deleteSlide', (updatedPresentationsList) => {
      if (
        updatedPresentationsList &&
        updatedPresentationsList.includes(data?._id)
      ) {
        refetch();
      }
    });

    socket.once('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [data, refetch]);

  if (error) {
    return <AlertBar severity="error" msg={error?.msg} />;
  }
  if (isLoading) {
    return <LinearProgress />;
  }
  if (!data) {
    return null;
  }

  return <PresentationPreview presentation={data} />;
};

export default PresentationPreviewPage;
