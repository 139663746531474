import { useMemo } from 'react';
import { CreateSlideResponse, useGetSlideById } from '../../api-http/slides';
import { useGetAssets } from '../../api-http/assets';
import { tranformAssetsForConfig } from '../../util/tranformAssetsForConfig';
import { AssetsResponse } from '../../types/assets.types';

const useGetSlidData = (paramId: string) => {
  const { data: slide, isLoading, error } = useGetSlideById(paramId || '');
  const {
    data: assets = [],
    isLoading: isLoadingAssets,
    error: errorAssets,
  } = useGetAssets();

  const combinWithSlide = (
    assets: AssetsResponse[],
    slide?: CreateSlideResponse,
  ) => {
    return {
      ...slide?.projectData,
      assets: tranformAssetsForConfig(assets),
    };
  };

  const projectData = useMemo(
    () => combinWithSlide(assets, slide),
    [assets, slide],
  );

  return {
    isLoading: isLoading || isLoadingAssets,
    error: error || errorAssets,
    slide,
    projectData,
  };
};

export default useGetSlidData;
