import React, { FC, useState } from 'react';

import styled from '@emotion/styled';
import { Button, Card, CardContent, Typography } from '@mui/material';

import { DialogModal } from '../../components';
import { PageTitle } from '../../components/PageTitle';
import { useAuth } from '../../context/auth-context';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useUserRoleName } from '../../util/useUserTypes';
import { ProfileChangePasswordForm } from './ProfileChangePasswordForm';
import { ProfileUpdateForm } from './ProfileUpdateForm';

const KeyTitle = styled('p')`
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

const Row = ({ title, value }: { title: string; value?: string }) => (
  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    <KeyTitle>{title}</KeyTitle>
    {value || ''}
  </Typography>
);

const Header = styled.div`
  flexdirection: row;
  display: flex;
  alignitems: center;
`;

const ProfilePage: FC = () => {
  metaTitle(' Profile');
  const { currentUser, refetchMe } = useAuth();
  const disableEdit = useIsUserRole([UserRole.user]);

  const [userUpdateModal, setUserUpdateModal] = useState(false);
  const [userChangePasswordModal, setUserChangePasswordModal] = useState(false);

  const onFinishProfileUpdate = () => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, ...rest } = currentUser;
    setUserUpdateModal(false);
    refetchMe();
  };

  return (
    <>
      <Header>
        <PageTitle>Profile</PageTitle>
      </Header>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Row title={'Name'} value={currentUser?.name} />
          <Row title={'Email'} value={currentUser?.email} />
          <Row
            title={'User Role'}
            value={useUserRoleName(currentUser?.role || '')}
          />
          <StyledButton
            disabled={disableEdit}
            onClick={() => setUserUpdateModal(true)}
          >
            Edit Your Profile
          </StyledButton>
        </CardContent>

        <CardContent>
          <Row title={'Organization'} value={currentUser?.organization.name} />

          <Row
            title={'Organization Pin Code'}
            value={currentUser?.organization.pinCode}
          />
        </CardContent>

        <CardContent>
          <StyledButton onClick={() => setUserChangePasswordModal(true)}>
            Change Password
          </StyledButton>
        </CardContent>
      </Card>

      <DialogModal
        onClose={() => setUserUpdateModal(false)}
        open={userUpdateModal}
        title={'Edit profile'}
      >
        <ProfileUpdateForm
          profileData={currentUser}
          onFinished={onFinishProfileUpdate}
        />
      </DialogModal>
      <DialogModal
        onClose={() => setUserChangePasswordModal(false)}
        open={userChangePasswordModal}
        title={'Change password'}
      >
        <ProfileChangePasswordForm
          onFinished={() => setUserChangePasswordModal(false)}
        />
      </DialogModal>
    </>
  );
};

export default ProfilePage;
