import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import FullScreenMode from '../../components/FullScreenMode';
import { useGetSlideById } from '../../api-http/slides';
import styled from 'styled-components';
import { Alert, LinearProgress } from '@mui/material';
import PresentationSlide from '../../components/preview/PresenationSlide';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #252525;
`;

const SlidePreviewPage = () => {
  const { id: paramId } = useParams<{ id: string }>();
  const { data: slide, error, isLoading } = useGetSlideById(paramId || '');

  if (error) {
    return (
      <Alert color="error">
        {error.msg}{' '}
        <Link to="/presentations">Go back go the presentation page</Link>
      </Alert>
    );
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <FullScreenMode backUrl="/slides" hideFullScreenBtn>
        <Container>
          {slide && (
            <PresentationSlide
              currentSlide={{
                slideData: slide,
              }}
              margin={window.innerWidth * 0.3}
            />
          )}
        </Container>
      </FullScreenMode>
    </>
  );
};

export default SlidePreviewPage;
