import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSwitchTransition } from 'transition-hook';

import { metaTitle } from '../../util/tabTitle';
import PresentationSlide from './PresenationSlide';
import { DEFAULT_TIME_OUT, DEFAULT_TRANS_TIME } from './constants';
import { GetPresentationResponse } from '../../api-http/presentations';
import { GetDisplayPresentationResponse } from '../../api-http/displays';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #252525;
`;

interface PresentationPreviewProps {
  presentation: GetDisplayPresentationResponse | GetPresentationResponse;
  margin?: number;
}

const PresentationPreview: FC<PresentationPreviewProps> = ({
  presentation,
  margin = 0,
}) => {
  metaTitle('Preview');

  const [slideIndex, setSlideIndex] = useState(0);

  let intervalId: ReturnType<typeof setTimeout>;

  const startInterval = (timeout: number) => {
    intervalId = setTimeout(() => {
      const current =
        slideIndex + 1 >= presentation.slides?.length ? 0 : slideIndex + 1;
      setSlideIndex(current);
    }, timeout);
  };

  useEffect(() => {
    if (presentation) {
      setSlideIndex(0);
      clearTimeout(intervalId);
    }
  }, [presentation]);

  useEffect(() => {
    const _currentSlide = presentation?.slides[slideIndex];

    if (_currentSlide) {
      startInterval(_currentSlide.duration || DEFAULT_TIME_OUT);
    }
    return () => clearTimeout(intervalId);
  }, [slideIndex, presentation]);

  useEffect(() => {
    return () => clearTimeout(intervalId);
  }, []);

  const currentSlide = presentation.slides[slideIndex];
  const transition = useSwitchTransition(currentSlide, DEFAULT_TRANS_TIME);

  return (
    <Container>
      {transition((state, stage) => {
        return (
          <PresentationSlide
            stage={stage}
            currentSlide={state}
            margin={margin}
          />
        );
      })}
    </Container>
  );
};

export default PresentationPreview;
