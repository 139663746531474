import { FC } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

interface PresentationActionMenuProps {
  onClickRename: () => void;
  onClickClone: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const PresentationActionMenu: FC<PresentationActionMenuProps> = ({
  onClickRename,
  onClickClone,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Menu
      id="presentation-actions"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem>
        <Button onClick={onClickRename}>Edit Presentation</Button>
      </MenuItem>
      <MenuItem>
        <Button onClick={onClickClone}>Clone Presentation</Button>
      </MenuItem>
    </Menu>
  );
};
