import { format, formatDistance, subDays } from 'date-fns';

export const displayDate = (date?: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : '-';
export const displayDateTime = (date?: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd HH:mm') : '-';
export const displayDistance = (date?: string) =>
  date
    ? formatDistance(subDays(new Date(date), 0), new Date(), {
        addSuffix: true,
      })
    : '-';
