import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

export interface CategoryResponse {
  _id: string;
  name: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

export type CreateCategoryBody = Pick<CategoryResponse, 'name'>;
export type CreateCategoryBodyResponse = Pick<
  CategoryResponse,
  'name' & { organizationId?: string }
>;
export type CreateCategoryResponse = CategoryResponse;
export type UpdateCategoryBody = Pick<CategoryResponse, '_id' | 'name'>;
export type GetCategoryResponse = CategoryResponse;

type UpdateCategoryResponse = Pick<CategoryResponse, '_id'>;
type DeleteCategoryResponse = Pick<CategoryResponse, '_id'>;

export const useCreateCategory = (opt?: MutOptions<CreateCategoryBody>) =>
  useMutation<CreateCategoryBody, ApiServiceErr, CreateCategoryBodyResponse>(
    async (data) => {
      const response = await axiosApi.post('/categories/create', data);
      return response.data;
    },
    opt,
  );

export const useGetAllCategory = () =>
  useQuery<CategoryResponse[], ApiServiceErr>(['categories/all'], async () => {
    const response = await axiosApi.get('/categories/all');
    return response.data;
  });

export const useDeleteCategory = (opt?: MutOptions<DeleteCategoryResponse>) =>
  useMutation<DeleteCategoryResponse, ApiServiceErr, DeleteBody>(
    async (data) => {
      const response = await axiosApi.delete(`/categories/delete/${data.id}`);
      return response.data;
    },
    opt,
  );

export const useUpdateCategory = (opt?: MutOptions<UpdateCategoryResponse>) =>
  useMutation<UpdateCategoryResponse, ApiServiceErr, UpdateCategoryBody>(
    async (data) => {
      const response = await axiosApi.put('/categories/update', data);
      return response.data;
    },
    opt,
  );

export const useGetPaginateCategory = ({
  limit,
  page,
  sortBy,
  order,
}: PaginateOptions) =>
  useQuery<PagingResponse<GetCategoryResponse>, ApiServiceErr>(
    [
      '/categories/paginate/categories?limit=${limit}&page=${page}&sortBy=${sortBy}&order=${order}',
      limit,
      page,
      sortBy,
      order,
    ],
    async () => {
      const response = await axiosApi.get(
        `/categories/paginate/categories?limit=${limit}&page=${page}&sortby=${sortBy}&order=${order}`,
      );
      return response.data;
    },
  );
