import * as React from 'react';
import { useEffect, useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { Edit, Trash } from 'react-feather';

import {
  Button,
  LinearProgress,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  CreateCategoryResponse,
  useDeleteCategory,
  useGetPaginateCategory,
} from '../../api-http/categories';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import { notifyMsg } from '../../configs';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { RenameForm } from './CategoryRenameForm';
import { useTable } from '../../util/useTable';
import { useSnack } from '../../util/useSnack';
import { useAuth } from '../../context/auth-context';

const CategoryTable: React.FC = () => {
  const confirm = useConfirm();
  const { switchedOrgId } = useAuth();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortBy, order } = pagingParams;

  const [open, setOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState<
    CreateCategoryResponse | undefined
  >();
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.layoutEditor]);

  const {
    data: list,
    isLoading,
    error,
    refetch,
  } = useGetPaginateCategory(pagingParams);

  const { error: deleteError, mutate } = useDeleteCategory({
    onSuccess: () => showSuccessSnack(notifyMsg.CATEGORY_DELETE_SUCCESS),
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const handleEdit = (category: CreateCategoryResponse) => {
    setOpen(true);
    setActiveCategory(category);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: 'Are you sure you want to delete?',
        confirmationText: 'Yes',
      });
      refetch();
      mutate({ id });
    } catch (e) {}
  };

  useEffect(() => {
    refetch();
  }, [open, switchedOrgId]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || deleteError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === SortEnum.title}
                  direction={sortBy === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  Category Title
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Updated</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === SortEnum.createdAt}
                  direction={
                    sortBy === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data.map((category) => (
              <TableRow
                key={category.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="category">
                  {category.name}
                </TableCell>
                <TableCell align="right">
                  {displayDistance(category.updatedAt)}
                </TableCell>
                <TableCell align="right">
                  {displayDistance(category.createdAt)}
                </TableCell>

                <TableCell align="right">
                  <Button
                    onClick={() => handleEdit(category)}
                    disabled={isViewOnly}
                  >
                    <Edit size={16} />
                  </Button>
                  <Button
                    onClick={() => handleDelete(category._id)}
                    disabled={isViewOnly}
                  >
                    <Trash size={16} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>
      <DialogModal
        title="Rename Category"
        onClose={() => setOpen(false)}
        open={open}
      >
        <RenameForm
          onFinished={() => setOpen(false)}
          category={activeCategory || undefined}
        />
      </DialogModal>
    </>
  );
};

export default CategoryTable;
