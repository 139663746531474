import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import OrganizationPage from './containers/admin/organizations/OrganizationPage';
import UserPage from './containers/admin/users/UserPage';
import AssetsPage from './containers/assets/AssetsPage';
import ForgotPasswordPage from './containers/auth/forgotPassword/ForgotPasswordPage';
import SignInPage from './containers/auth/signIn/SignInPage';
import SignUpPage from './containers/auth/signUp/SignUpPage';
import CategoryPage from './containers/categories/CategoryPage';
import DisplayListPage from './containers/displays/displayList/DisplayList';
import DisplaySignInPage from './containers/displays/signIn/DisplaySignInPage';
import HomePage from './containers/home/Home';
import Layout from './containers/layout/Layout';
import NotFoundPage from './containers/not-found/NotFoundPage';
import PresentationPreviewPage from './containers/presentation-preview/PresentationPreviewPage';
import PresentationPublicPreviewPage from './containers/presentation-public-preview/PresentationPublicPreviewPage';
import PresentationCreatePage from './containers/presentations/PresentationEditorPage';
import PresentationPage from './containers/presentations/PresentationPage';
import ProfilePage from './containers/profile/ProfilePage';
import SlideEditorPage from './containers/slide-editor/SlideEditorPage';
import SlidePreviewPage from './containers/slide-preview/SlidePreviewPage';
import SlidesPage from './containers/slide/SlidesPage';
import UsersPage from './containers/users/UsersPage';
import { useAuth } from './context/auth-context';

interface RequireAuthProps {
  comp: JSX.Element;
}

const RequireAuth = ({ comp }: RequireAuthProps) => {
  // Ref https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx#L130

  const { isLoggedIn } = useAuth();
  const location = useLocation();
  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={'/sign-in'} state={{ from: location }} replace />;
  }

  return comp;
};

export default function NavigationRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RequireAuth comp={<Layout />} />}>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/profile'} element={<ProfilePage />} />
          <Route path={'/users'} element={<UsersPage />} />
          <Route path={'/presentations'} element={<PresentationPage />} />
          <Route path={'/slides'} element={<SlidesPage />} />
          <Route path={'/assets'} element={<AssetsPage />} />
          <Route path={'/admin/organizations'} element={<OrganizationPage />} />
          <Route path={'/admin/users/:id'} element={<UserPage />} />
          <Route path={'/categories'} element={<CategoryPage />} />
          <Route
            path={'/presentations-editor/:id'}
            element={<PresentationCreatePage />}
          />
        </Route>
        <Route
          path={'/presentation-preview/:id'}
          element={<PresentationPreviewPage />}
        />
        <Route
          path={'/slides-editor/:id'}
          element={<RequireAuth comp={<SlideEditorPage />} />}
        />
        <Route
          path={'/slides-preview/:id'}
          element={<RequireAuth comp={<SlidePreviewPage />} />}
        />
        <Route path={'/display-login'} element={<DisplaySignInPage />} />
        <Route path={'/display-list'} element={<DisplayListPage />} />
        <Route
          path={'/presentation-public-preview/:id'}
          element={<PresentationPublicPreviewPage />}
        />
        <Route path={'/sign-up'} element={<SignUpPage />} />
        <Route path={'/forgot-password'} element={<ForgotPasswordPage />} />
        <Route path={'/sign-in'} element={<SignInPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
