import * as React from 'react';
import { FC } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';

interface EditActionMenuProps {
  onClickRename: () => void;
  onClickEditDynamic: () => void;
  onClickClone: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const EditActionMenu: FC<EditActionMenuProps> = ({
  onClickRename,
  onClickEditDynamic,
  onClickClone,
  onClose,
  open,
  anchorEl,
}) => {
  const isViewOnly = useIsUserRole([UserRole.dataEditor]);
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem>
        <Button onClick={onClickRename}>Rename Slide</Button>
      </MenuItem>
      <MenuItem>
        <Button onClick={onClickEditDynamic}>Edit dynamic values</Button>
      </MenuItem>
      <MenuItem>
        <Button disabled={isViewOnly} onClick={onClickClone}>
          Clone Slide
        </Button>
      </MenuItem>
    </Menu>
  );
};
