import React, { FC, useEffect, useState } from 'react';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { useGetAllCategory } from '../../api-http/categories';
import { DialogModal } from '../../components';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { PresentationForm } from './PresentationForm';
import { PresentationTable } from './PresentationTable';
import { FilterSelect } from '../../components/common/FilterSelect';
import { FilterSelectWrapper } from '../../components/common/FilterSelectWrapper';
import { useAuth } from '../../context/auth-context';

const PresentationPage: FC = () => {
  metaTitle('Presentations');
  const [open, setOpen] = React.useState(false);
  const { data: categoryData, refetch } = useGetAllCategory();
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const { switchedOrgId } = useAuth();
  const handleChange = (event: SelectChangeEvent<typeof selectedCategory>) => {
    const {
      target: { value },
    } = event;
    setSelectedCategory(value);
  };

  useEffect(() => {
    refetch();
  }, [switchedOrgId]);

  const disableAddNew = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  return (
    <>
      <PageTitle>Presentations</PageTitle>
      <Stack>
        <FilterSelectWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            disabled={disableAddNew}
          >
            Add new
          </Button>
          <FilterSelect>
            <FormControl
              sx={{
                '& > :not(style)': {
                  m: 1,
                  width: '30ch',
                  height: '50px',
                  paddingLeft: '10px',
                  fontSize: '14px',
                },
              }}
            >
              <InputLabel id="category">Search by category</InputLabel>

              <Select
                id="category"
                name="category"
                label="category"
                title="category"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categoryData?.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FilterSelect>
        </FilterSelectWrapper>
        <PresentationTable selectedCategory={selectedCategory} />
      </Stack>

      <DialogModal
        title="Add New Presentation"
        onClose={() => setOpen(false)}
        open={open}
      >
        <PresentationForm onFinished={() => setOpen(false)} />
      </DialogModal>
    </>
  );
};

export default PresentationPage;
