import * as React from 'react';
import { useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { ChevronDown, Edit, Trash, Tv } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import {
  CreateSlideResponse,
  useCreateSlide,
  useDeleteSlide,
  useGetPaginateSlide,
} from '../../api-http/slides';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import { notifyMsg } from '../../configs';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { EditActionMenu } from './EditActionMenu';
import KeyValueForm from '../../components/KeyValueForm';
import { RenameSlideForm } from './RenameSlideForm';
import { useAuth } from '../../context/auth-context';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';

const SlideTable: React.FC = () => {
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  const disableEdit = useIsUserRole([UserRole.user]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const confirm = useConfirm();
  const { switchedOrgId } = useAuth();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortBy, order } = pagingParams;

  const {
    data: list,
    isLoading: getAllSlidesIsLoading,
    error,
    refetch,
    isRefetching,
  } = useGetPaginateSlide(pagingParams);

  const { error: deleteError, mutate: deleteSlide } = useDeleteSlide({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.SLIDE_DELETE_SUCCESS);
    },
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const { mutate: createSlide, error: createError } = useCreateSlide({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.SLIDE_CLONE_SUCCESS);
    },
    onError: (err) => showErrSnack(err),
  });

  const [renameSlideModal, setRenameSlideModal] = useState(false);
  const [editDynamicDataModal, setEditDynamicDataModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState<
    CreateSlideResponse | undefined
  >();

  React.useEffect(() => {
    refetch();
  }, [switchedOrgId]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    slide: CreateSlideResponse,
  ) => {
    setActiveSlide(slide);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      deleteSlide({ id });
    } catch (e) {}
  };

  const clone = async () => {
    createSlide({
      dynamicData: activeSlide?.dynamicData || [],
      projectData: activeSlide?.projectData,
      status: activeSlide?.status || true,
      title: `${activeSlide?.title}_clone`,
    });
    handleClose();
  };

  return (
    <>
      {(getAllSlidesIsLoading || isRefetching) && <LinearProgress />}
      <AlertBar
        severity="error"
        msg={error?.msg || deleteError?.msg || createError?.msg}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === SortEnum.title || true}
                  direction={sortBy === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  Slide Title
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" />
              <TableCell align="right">Device</TableCell>
              <TableCell align="right">Updated</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === SortEnum.createdAt || true}
                  direction={
                    sortBy === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data.map((slide) => {
              return (
                <TableRow
                  key={slide._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {isViewOnly ? (
                      slide.title
                    ) : (
                      <NavLink to={`/slides-editor/${slide._id}`}>
                        <Edit size={12} style={{ marginRight: 10 }} />

                        {slide.title}
                      </NavLink>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <NavLink to={`/slides-preview/${slide._id}`}>
                      Preview
                      <Tv size={12} style={{ marginLeft: 10 }} />
                    </NavLink>
                  </TableCell>
                  <TableCell align="right">
                    {slide.projectData?.deviceInfo?.name}(
                    {slide.projectData?.deviceInfo?.type})
                  </TableCell>
                  <TableCell align="right">
                    {displayDistance(slide.updatedAt)}
                  </TableCell>
                  <TableCell align="right">
                    {displayDistance(slide.createdAt)}
                  </TableCell>

                  <TableCell align="right">
                    <Button
                      onClick={(event) => handleClick(event, slide)}
                      disabled={disableEdit}
                    >
                      <ChevronDown size={16} />
                    </Button>

                    <Button
                      onClick={() => handleDelete(slide._id)}
                      disabled={isViewOnly}
                    >
                      <Trash size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <EditActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClickEditDynamic={() => {
          setEditDynamicDataModal(true);
          handleClose();
        }}
        onClickRename={() => {
          setRenameSlideModal(true);
          handleClose();
        }}
        onClickClone={clone}
      />

      <DialogModal
        title={'Edit Dynamic Values'}
        onClose={() => setEditDynamicDataModal(false)}
        open={editDynamicDataModal}
      >
        <KeyValueForm
          onClose={() => {
            setEditDynamicDataModal(false);
            refetch();
          }}
          slide={list && activeSlide}
        />
      </DialogModal>

      {activeSlide && (
        <DialogModal
          title={'Rename slide'}
          onClose={() => setRenameSlideModal(false)}
          open={renameSlideModal}
        >
          <RenameSlideForm
            onFinished={() => {
              setRenameSlideModal(false);
              refetch();
            }}
            slide={activeSlide}
          />
        </DialogModal>
      )}
    </>
  );
};

export default SlideTable;
