import { FC, useEffect, useState } from 'react';
import WebEditor from '../../components/web-editor/WebEditor';
import styled from 'styled-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Alert, Button, LinearProgress } from '@mui/material';
import { useUpdateSlide } from '../../api-http/slides';
import { metaTitle } from '../../util/tabTitle';
import useGetSlidData from './hook';

const GoBackContainer = styled('div')`
  position: absolute;
  width: 200px;
  background-color: #444444;
  z-index: 4;
`;

const SlideCreatePage: FC = () => {
  metaTitle('Slide Editor');
  const { id: paramId } = useParams<{
    id: string;
  }>();

  const [searchParams] = useSearchParams();
  const { slide, projectData, isLoading, error } = useGetSlidData(
    paramId || '',
  );

  const { mutate } = useUpdateSlide();

  const [preview, setPreview] = useState(false);

  useEffect(() => {
    document.title = slide?.title || '';
  }, [paramId]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (error) {
    return (
      <Alert color="error">
        {error?.msg ?? 'The slide data is not available'}
        <Link to="/slides">Go back go the slides page</Link>
      </Alert>
    );
  }

  if (!slide || !paramId) {
    return (
      <Alert color="error">
        The slide data is not available.{' '}
        <Link to="/slides">Go back go the slides page</Link>
      </Alert>
    );
  }

  return (
    <>
      {!preview && (
        <GoBackContainer>
          <Link to={searchParams.get('goBackUrl') ?? '/slides'}>
            <Button>Go Back</Button>
          </Link>
        </GoBackContainer>
      )}
      <WebEditor
        initialProjectData={projectData}
        projectKey={paramId || ''}
        onUpdate={(data) => {
          if (paramId) {
            mutate({
              id: slide?._id || '',
              title: slide?.title,
              status: true,
              projectData: {
                ...data,
                assets: [],
                deviceInfo: slide?.projectData?.deviceInfo,
              },
              dynamicData: slide?.dynamicData,
            }); // TODO: Remove optional data
          }
        }}
        onPreview={(isPreview) => setPreview(isPreview)}
      />
    </>
  );
};

export default SlideCreatePage;
