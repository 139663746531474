import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, LinearProgress, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import PresentationEditorAddSlides from './PresentationEditorAddSlides';
import PublishBox from './PublishBox';
import { PreviewList } from './PreviewList';
import { PageTitle } from '../../components/PageTitle';
import {
  SlideWithOptions,
  useGetPresentation,
  useUpdatePresentation,
} from '../../api-http/presentations';
import { useGetAllSlides } from '../../api-http/slides';
import { AlertBar } from '../../components/AlertBar';
import { AnimationEnum, TRANSITIONS } from '../../components/preview/constants';

export interface TransitionSelectProps {
  index: number;
  slideId: string;
  slideData: string;
  duration: number;
  animation: string;
}

const PresentationCreatePage: FC = () => {
  const params = useParams<{ id: string }>();

  const { data, error, isLoading, refetch } = useGetPresentation(
    params.id || '',
  );

  const {
    data: orgSlides,
    isLoading: loadingSlides,
    error: slidesError,
  } = useGetAllSlides();

  const {
    isLoading: isLoadingUpdating,
    error: updatePresentationError,
    mutate: updatePresentation,
    data: updatedPresentation,
  } = useUpdatePresentation();

  const [slides, setSlides] = useState<SlideWithOptions[]>(data?.slides || []);

  const transformPresentation = () => {
    if (!data) {
      return null;
    }
    const slidesList = data?.slides.map((slide) => {
      return {
        animation: slide.animation,
        duration: slide.duration,
        slideId: slide.slideId,
        slideData: slide.slideId,
      };
    });
    return {
      id: data?._id,
      name: data.name,
      slides: slidesList,
      status: data.status,
    };
  };

  const handleSlideSelectChange = (id: string) => {
    const updateData = transformPresentation();
    if (updateData && id) {
      updatePresentation({
        ...updateData,
        slides: [
          ...updateData.slides,
          {
            slideId: id,
            slideData: id,
            animation: AnimationEnum.fade,
            duration: 3000,
          },
        ],
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [updatedPresentation]);

  useEffect(() => {
    setSlides(data?.slides || []);
  }, [data]);

  useEffect(() => {
    onPresentationUpdate();
  }, [slides]);

  const handleDurationSelectChange = (data: {
    event: any;
    index: number;
    slideId: string;
    animation: string;
  }) => {
    const slideData = {
      slideId: data.slideId,
      slideData: data.slideId,
      animation: data.animation,
      duration: data.event.target.value * 1000,
    };
    const updateData = transformPresentation();
    if (updateData && data.event.target.value) {
      updateData.slides[data.index] = slideData;
      updatePresentation(updateData);
    }
    onPresentationUpdate();
  };

  const handleTransitionSelectChange = (data: TransitionSelectProps) => {
    const { slideId, animation, duration } = data;
    const slideData = {
      slideId,
      slideData: slideId,
      animation,
      duration,
    };
    const updateData = transformPresentation();
    if (updateData && animation) {
      updateData.slides[data.index] = slideData;
      updatePresentation(updateData);
    }
    onPresentationUpdate();
  };

  const onPresentationUpdate = () => {
    // TODO
  };

  const onSlideRemove = (index: number) => {
    const updateData = transformPresentation();
    if (updateData) {
      delete updateData.slides[index];

      updatePresentation({
        ...updateData,
        slides: updateData.slides.filter(Boolean),
      });
    }
  };

  const onSlideOrderChange = (slides: any) => {
    const updateData = transformPresentation();
    if (updateData) {
      updatePresentation({
        ...updateData,
        slides,
      });
    }
  };

  if (!data && isLoading) {
    return <LinearProgress />;
  }

  if (!data || !params.id) {
    return (
      <>
        <AlertBar severity="error" msg={error?.msg} />
        <Alert color="error">
          The presentation data is not available.{' '}
          <Link to="/presentations">Go back go the slides page</Link>
        </Alert>
      </>
    );
  }
  return (
    <>
      {(isLoading || loadingSlides || isLoadingUpdating) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg} />
      <AlertBar severity="error" msg={slidesError?.msg} />

      <AlertBar severity="error" msg={updatePresentationError?.msg} />
      <PageTitle>{data.name}</PageTitle>
      <Stack gap={2}>
        <PresentationEditorAddSlides
          id={params.id}
          slides={orgSlides || []}
          slideOnChange={handleSlideSelectChange}
        />
        <PreviewList
          slides={slides as any}
          onSlideRemove={onSlideRemove}
          durationOnChange={handleDurationSelectChange}
          transitionOnChange={handleTransitionSelectChange}
          onSlideOrderChange={onSlideOrderChange}
          initialTransitions={TRANSITIONS}
          setSlides={setSlides}
          refetchPresentation={() => refetch()}
        />
        <PublishBox presentationKey={params.id} presentation={data} />
      </Stack>
    </>
  );
};

export default PresentationCreatePage;
