/* eslint-disable @typescript-eslint/no-explicit-any */

import 'grapesjs/dist/css/grapes.min.css';
import './assets/grapeJS.css';

import grapesjs from 'grapesjs';
import gjsBasicBlocks from 'grapesjs-blocks-basic';
import gjsCountdown from 'grapesjs-component-countdown';
import gjsCustomCode from 'grapesjs-custom-code';
import gjsImageEditor from 'grapesjs-tui-image-editor';
import gjsTimerBlock from './configs/timer-plugin';
import gjsTxtWithSliderCtrlBlock from './configs/txt-with-slider-ctrl-plugin';

import devices from './assets/devices.json';
import { blocks } from './configs/app-blocks';
import { getAppToolBar } from './configs/app-toolbars';
import { GrapeJsEditorConfigOptions, GrapeJsEditorOptions } from './types';
import { resizeToFitToWidth } from './utils';

const getProjectConfig = (
  projectKey: string,
  options: GrapeJsEditorConfigOptions,
): GrapeJsEditorConfigOptions => {
  const { deviceInfo, ...configOptions } = options;
  const selectedDevice = deviceInfo || devices[0];

  return {
    container: '#gjs',
    fromElement: true,
    width: 'auto',
    allowScripts: true,
    deviceManager: {
      devices: [selectedDevice],
    },
    plugins: [
      gjsBasicBlocks,
      gjsImageEditor,
      gjsCountdown,
      gjsCustomCode,
      gjsTimerBlock,
      gjsTxtWithSliderCtrlBlock,
    ],
    blockManager: {
      blocks,
    },
    storageManager: {
      type: 'session',
      autosave: true,
      autoload: false,
      options: {
        session: { key: projectKey },
      },
    },
    ...configOptions,
  };
};

export const getGrapesEditor = (
  projectKey: string,
  config: GrapeJsEditorConfigOptions,
): GrapeJsEditorOptions => {
  const editor = grapesjs.init(getProjectConfig(projectKey, config));
  getAppToolBar(editor);

  editor.onReady(() => {
    resizeToFitToWidth(editor);
  });

  editor.on('run:preview', () => {
    resizeToFitToWidth(editor);
  });

  editor.on('stop:preview', () => {
    resizeToFitToWidth(editor);
  });

  return editor;
};
