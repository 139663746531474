import React, { FC, useEffect, useState } from 'react';
import { Button, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { useUploadAsset } from '../../api-http/assets';
import { AlertBar } from '../../components/AlertBar';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../api-http/apiService';
import { notifyMsg } from '../../configs';
import { ERROR, SUCCESS } from '../../util/constant';

interface AddAssetFormProps {
  onFinished: () => void;
}

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const AddAssetForm: FC<AddAssetFormProps> = ({ onFinished }) => {
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const { mutate, isLoading, error, data, isSuccess, isError } =
    useUploadAsset();
  const { enqueueSnackbar } = useSnackbar();

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', selectedImage);

    mutate(formData);
  };

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.ASSET_ADDED_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  return (
    <Container>
      {/* {selectedImage && (
        <div>
          <img
            alt="not found"
            width={'250px'}
            src={URL.createObjectURL(selectedImage?.target?.files[0])}
          />
          <br />
          <button onClick={() => setSelectedImage(null)}>Remove</button>
        </div>
      )} */}
      <AlertBar severity="error" msg={error?.msg} />
      {isLoading && <LinearProgress />}
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage(event.target?.files?.[0]);
        }}
        accept="image/*, video/*"
        style={{ marginTop: 20 }}
      />

      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={isLoading || !selectedImage}
        style={{ marginTop: 20 }}
        onClick={uploadFile}
      >
        Upload
      </Button>
    </Container>
  );
};
