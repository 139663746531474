/**
 * Tutorials
 * - https://grapesjs.com/docs/modules/Blocks.html#block-content-types
 */
const CATEGORY = 'Custom Blocks';

export const blocks = [
  {
    id: 'h1_tag',
    label: 'H1 Tag',
    category: CATEGORY,
    media: `
      <svg width="32px" height="32px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-type-h1">
        <path d="M8.637 13V3.669H7.379V7.62H2.758V3.67H1.5V13h1.258V8.728h4.62V13h1.259zm5.329 0V3.669h-1.244L10.5 5.316v1.265l2.16-1.565h.062V13h1.244z"/>
      </svg>`,
    content: `<h1>H1</h1>`,
    activate: true,
  },
  {
    id: 'h2_tag',
    label: 'H2 Tag',
    category: CATEGORY,

    media: `
    <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M9 7h2v10H9v-4H5v4H3V7h2v4h4V7zm8 8c.51-.41.6-.62 1.06-1.05.437-.4.848-.828 1.23-1.28.334-.39.62-.82.85-1.28.2-.39.305-.822.31-1.26.005-.44-.087-.878-.27-1.28-.177-.385-.437-.726-.76-1-.346-.283-.743-.497-1.17-.63-.485-.153-.99-.227-1.5-.22-.36 0-.717.033-1.07.1-.343.06-.678.158-1 .29-.304.13-.593.295-.86.49-.287.21-.56.437-.82.68l1.24 1.22c.308-.268.643-.502 1-.7.35-.2.747-.304 1.15-.3.455-.03.906.106 1.27.38.31.278.477.684.45 1.1-.014.396-.14.78-.36 1.11-.285.453-.62.872-1 1.25-.44.43-.98.92-1.59 1.43-.61.51-1.41 1.06-2.16 1.65V17h8v-2h-4z"/></g></svg>`,
    content: `<h2>H2</h2>`,
    activate: true,
  },
  {
    id: 'currency_block',
    label: 'Text with title',
    category: CATEGORY,
    media: `
    <svg width="32px" height="32px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 11h-11c-.275 0-.5.225-.5.5v1a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0-4h-11c-.275 0-.5.225-.5.5v1a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-5 8h-6c-.275 0-.5.225-.5.5v1a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm5-12h-11c-.275 0-.5.225-.5.5v1a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/></svg>`,
    content: `<div>
    <h1>Hello</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer imperdiet cursus massa quis malesuada. Ut ut lectus varius risus congue congue. Ut porta nunc in erat efficitur, eget porttitor velit cursus. Quisque laoreet accumsan euismod. Etiam at erat in ligula pharetra porta nec convallis arcu. Integer at hendrerit nisi, eget pharetra augue. Morbi pharetra urna eu dui finibus sollicitudin. Quisque sit amet accumsan libero, ut dapibus lacus. Nulla justo purus, tristique eu neque vel, rhoncus pellentesque quam. Quisque ornare tempor neque a mattis. Aenean lobortis gravida risus, vel vestibulum quam sodales sed. Aliquam facilisis sapien eu facilisis viverra. Suspendisse rutrum varius lacus, rutrum fringilla erat blandit ac. Sed non augue semper, condimentum velit quis, dapibus libero. Suspendisse rhoncus vulputate mollis.</p>
    <div>`,
    activate: true,
  },
  {
    id: 'fit-to-width-image',
    label: 'Fit To Width Image',
    category: CATEGORY,
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16"> <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/> </svg>`,
    content: {
      type: 'image',
      style: {
        width: '100vw',
        height: 'auto',
        position: 'absolute',
      },
    },
    activate: true,
  },
  {
    id: 'fit-to-height-image',
    label: 'Fit To Height Image',
    category: CATEGORY,
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-file-image" viewBox="0 0 16 16"> <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z"/> </svg>`,
    content: {
      type: 'image',
      style: {
        width: 'auto',
        height: '100vh',
        position: 'fixed',
      },
    },
    activate: true,
  },
  {
    id: 'full-video',
    label: 'Full Screen Video',
    category: CATEGORY,
    media: `
    <svg style="color: white" width="32px" height="32px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 832a384 384 0 0 0 0-768 384 384 0 0 0 0 768zm-48-247.616L668.608 512 464 375.616v272.768zm10.624-342.656 249.472 166.336a48 48 0 0 1 0 79.872L474.624 718.272A48 48 0 0 1 400 678.336V345.6a48 48 0 0 1 74.624-39.936z"></path></svg>`,
    content: {
      type: 'video',
      style: { width: '100vw', height: '100vh', position: 'fixed' },
    },
    activate: false,
  },
];
