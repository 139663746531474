import React, { FC, useState } from 'react';

import { Button } from '@mui/material';

import { DialogModal } from '../../components';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { CategoryForm } from './CategoryForm';
import CategoryTable from './CategoryTable';
import { FilterSelectWrapper } from '../../components/common/FilterSelectWrapper';

const CategoryPage: FC = () => {
  metaTitle('Categories');
  const [open, setOpen] = useState(false);

  const disableAddNew = useIsUserRole([
    UserRole.user,
    UserRole.layoutEditor,
    UserRole.dataEditor,
  ]);

  return (
    <>
      <PageTitle>Categories</PageTitle>

      <FilterSelectWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={disableAddNew}
        >
          Add new
        </Button>
      </FilterSelectWrapper>
      <CategoryTable />
      <DialogModal
        title="Add New Category"
        onClose={() => setOpen(false)}
        open={open}
      >
        <CategoryForm onFinished={() => setOpen(false)} />
      </DialogModal>
    </>
  );
};

export default CategoryPage;
