import React, { FC, useEffect } from 'react';
import { Avatar, Paper, Typography } from '@mui/material';
import styled from 'styled-components/macro';

import SignInComponent from './SignInForm';
import { metaTitle } from '../../../util/tabTitle';
import { useNavigate } from 'react-router-dom';
import { DISPLAY_HOST_NAME } from '../../../configs';

const MainWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7ebf0;
`;

const Wrapper = styled(Paper)`
  padding: 28px;
  max-width: 400px;
  margin: 12px;
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

const SignIn: FC = () => {
  metaTitle('Sign In');
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.host === DISPLAY_HOST_NAME) {
      navigate('/display-login');
    }
  }, []);
  return (
    <MainWrapper>
      <Wrapper>
        <BigAvatar alt="A" src="/static/img/avatars/avatar-1.jpg" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Advertex
        </Typography>

        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Sign in to your account to continue
        </Typography>
        <br />

        <SignInComponent />
      </Wrapper>
    </MainWrapper>
  );
};

export default SignIn;
