import { DynamicKeyValue, ProjectData } from '../types/slide.types';

type SlidePreviewLargeProp = Pick<ProjectData, 'css' | 'elements'> & {
  dynamicData: DynamicKeyValue[];
  muteVideo?: boolean;
};

const fixVideoAutoPlay = (updatedElement: string) =>
  updatedElement.replaceAll(
    '&autoplay=1',
    'autohide=1&mute=1&showinfo=0&controls=0&autoplay=1',
  );
const fixVideoMute = (updatedElement: string) =>
  updatedElement.replaceAll(
    'autoplay="autoplay" controls',
    'autoplay="autoplay" muted',
  );
export const getHtmlForPreview = ({
  elements = '',
  css = '',
  dynamicData = [],
  muteVideo,
}: SlidePreviewLargeProp) => {
  let updatedElement = elements;
  dynamicData.forEach(({ key, value }) => {
    updatedElement = updatedElement.replaceAll(`{${key}}`, value);
  });

  updatedElement = fixVideoAutoPlay(updatedElement);
  if (muteVideo) {
    updatedElement = fixVideoMute(updatedElement);
  }

  return `<style>
        ${css}
        </style>
        <div> ${updatedElement}</div>
        `;
};
