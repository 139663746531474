import React, { FC, useEffect, useRef, memo } from 'react';

import { Nullable } from '../../types';
import { GrapeJsEditorOptions } from './types';
import { getGrapesEditor } from './getGrapesEditor';
import { injectEditorOverFlowFix } from './utils';

interface WebEditorProps {
  initialProjectData: any;
  projectKey: string;
  onUpdate: (data: object) => void;
  onPreview: (preview: boolean) => void;
}

const WebEditor: FC<WebEditorProps> = ({
  initialProjectData,
  projectKey,
  onUpdate,
  onPreview,
}) => {
  const editor = useRef<Nullable<GrapeJsEditorOptions>>(null);

  useEffect(() => {
    editor.current = getGrapesEditor(projectKey, initialProjectData);
    editor.current.loadProjectData(initialProjectData);

    editor.current?.Storage.add('session', {
      load: async () => Promise.resolve({}),
      store: async (data: object) => {
        const css = editor.current?.getCss();
        const elements = editor.current?.getHtml();
        onUpdate({ ...data, elements, css });
      },
    });

    editor.current.on('run:preview', () => {
      onPreview(true);
    });

    editor.current.on('stop:preview', () => {
      onPreview(false);
    });
    injectEditorOverFlowFix(editor.current);

    return () => {
      editor.current?.destroy();
    };
  }, [projectKey, initialProjectData]);

  return (
    <>
      <div id="gjs" />
    </>
  );
};

export default memo(WebEditor);
