import * as React from 'react';
import { FC } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';
import { UserRole } from '../../types/user.types';

interface UserActionMenuProps {
  onClickResetPassword: () => void;
  onClickDelete: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
  activeUserRole?: string;
}

export const UserActionMenu: FC<UserActionMenuProps> = ({
  onClickResetPassword,
  onClickDelete,
  onClose,
  open,
  anchorEl,
  activeUserRole,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      {activeUserRole !== UserRole.orgAdmin && (
        <MenuItem>
          <Button onClick={onClickResetPassword}>Reset Password</Button>
        </MenuItem>
      )}
      <MenuItem>
        <Button onClick={onClickDelete}>Delete</Button>
      </MenuItem>
    </Menu>
  );
};
