import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { PageTitle } from '../../components/PageTitle';
import { metaTitle } from '../../util/tabTitle';
import { useAuth } from '../../context/auth-context';

const HomePage: FC = () => {
  metaTitle('Home');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  return (
    <div>
      <PageTitle>Home Page</PageTitle>

      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Beta Release
          </Typography>

          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Codename: {currentUser?.organization?.pinCode}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => navigate('/slides')}>
            get started
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default HomePage;
