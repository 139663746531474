import { FC, useState } from 'react';
import { Button } from '@mui/material';

import { PageTitle } from '../../components/PageTitle';
import { metaTitle } from '../../util/tabTitle';
import { AssetsTable } from './AssetsTable';
import { useIsUserRole } from '../../util/useIsUserRole';
import { UserRole } from '../../types/user.types';
import { AddAssetForm } from './AddAssetForm';
import { DialogModal } from '../../components';
import { FilterSelectWrapper } from '../../components/common/FilterSelectWrapper';

const AssetsPage: FC = () => {
  metaTitle('Assets');

  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const disableUploadAssets = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
  ]);

  return (
    <>
      <PageTitle>Assets</PageTitle>
      <FilterSelectWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddAssetModal(true)}
          disabled={disableUploadAssets}
          style={{ marginBottom: 0 }}
        >
          Add Asset
        </Button>
      </FilterSelectWrapper>

      <AssetsTable refresh={refreshTable} />

      <DialogModal
        title="Add asset"
        onClose={() => {
          setOpenAddAssetModal(false);
        }}
        open={openAddAssetModal}
      >
        <AddAssetForm
          onFinished={() => {
            setOpenAddAssetModal(false);
            setRefreshTable(!refreshTable);
          }}
        />
      </DialogModal>
    </>
  );
};

export default AssetsPage;
