import { BookOpen, Film, Grid, User, Users, Video } from 'react-feather';

import { NavItemOption, NavItemPageOptions } from './types';

const pagesSection: NavItemOption[] = [
  {
    href: '/presentations',
    icon: BookOpen,
    title: 'Presentations',
  },
  {
    href: '/slides',
    icon: Film,
    title: 'Slides',
  },
  {
    href: '/assets',
    icon: Video,
    title: 'Assets',
  },
  {
    href: '/categories',
    icon: Grid,
    title: 'Categories',
  },
];

const settingsSection: NavItemOption[] = [
  {
    href: '/profile',
    icon: User,
    title: 'Profile',
  },
  {
    href: '/users',
    icon: Users,
    title: 'Users',
  },
];

const navItems: NavItemPageOptions[] = [
  {
    title: 'Advertex®',
    pages: pagesSection,
  },
  {
    title: 'Settings',
    pages: settingsSection,
  },
];

export const navSuperAdminItems: NavItemPageOptions[] = [
  {
    title: 'Platform Ops',
    pages: [
      {
        href: '/admin/organizations',
        icon: Users,
        title: 'Admin Panel',
      },
    ],
  },
];

export default navItems;
