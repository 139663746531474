/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import type grapesjs from 'grapesjs';
const CATEGORY = 'Custom Blocks';

export type PluginOptions = {
  /**
   * The ID used to create the block and component
   * @default 'timerblock'
   */
  id?: string;

  /**
   * The label used for the block and the component.
   * @default 'TimerBlock'
   */
  label?: string;

  /**
   * Object to extend the default block. Pass a falsy value to avoid adding the block.
   * @example
   * { label: 'TimerBlock', category: 'Extra', ... }
   */
  block?: Partial<grapesjs.BlockOptions>;

  /**
   * Object to extend the default component properties.
   * @example
   * { name: 'TimerBlock', droppable: false, ... }
   */
  props?: grapesjs.ComponentDefinition;

  /**
   * Custom CSS styles for the component. This will replace the default one.
   * @default ''
   */
  style?: string;

  /**
   * Additional CSS styles for the component. These will be appended to the default one.
   * @default ''
   */
  styleAdditional?: string;
  format?: string;

  /**
   * TimerBlock component class prefix.
   * @default 'timerblock'
   */
  classPrefix?: string;
};

type TElement = HTMLElement & { __gjsTimerCompInterval: NodeJS.Timer };

declare global {
  interface Window {
    __gjsTimerCompIntervals: TElement[];
  }
}

const plugin: grapesjs.Plugin<PluginOptions> = (editor, opts = {}) => {
  const options: PluginOptions = {
    id: 'timerblock',
    label: 'Time',
    block: {},
    props: {},
    style: '',
    styleAdditional: '',
    format: 'format1',
    classPrefix: 'timerblock',
    ...opts,
  };

  const { block, props, style } = options;
  const id = options.id!;
  const label = options.label!;
  const pfx = options.classPrefix!;

  // Create block
  if (block) {
    editor.Blocks.add(id, {
      media: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="64" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
    </svg>`,
      label,
      category: CATEGORY,
      select: true,
      content: { type: id },
      ...block,
    });
  }

  const timerScript = function (props: Record<string, any>) {
    //@ts-ignore
    const el: TElement = this;
    const timerEl = el.querySelector('[data-js=timerblock]') as HTMLElement;

    const oldInterval = el.__gjsTimerCompInterval;
    oldInterval && clearInterval(oldInterval);

    const connected: TElement[] = window.__gjsTimerCompIntervals || [];
    const toClean: TElement[] = [];
    connected.forEach((item: TElement) => {
      if (!item.isConnected) {
        clearInterval(item.__gjsTimerCompInterval);
        toClean.push(item);
      }
    });
    connected.indexOf(el) < 0 && connected.push(el);
    window.__gjsTimerCompIntervals = connected.filter(
      (item) => toClean.indexOf(item) < 0,
    );

    const moveTimer = function () {
      const time = new Date();
      let hour: string | number = time.getHours();
      let min: string | number = time.getMinutes();
      let sec: string | number = time.getSeconds();
      const year = time.getFullYear();
      const month = 1 + time.getMonth();
      const date = time.getDate();

      hour = hour < 10 ? '0' + hour : hour;
      min = min < 10 ? '0' + min : min;
      sec = sec < 10 ? '0' + sec : sec;

      let currentTime = `${date}/${month}/${year} ${hour}:${min}:${sec}`;
      if (props.format == 'format2') {
        currentTime = `${year}-${month}-${date} ${hour}:${min}:${sec}`;
      }
      timerEl.innerHTML = currentTime;
    };

    el.__gjsTimerCompInterval = setInterval(moveTimer, 1000);
    moveTimer();
  };

  editor.Components.addType(id, {
    model: {
      defaults: {
        format: options.format,
        classes: [pfx],
        droppable: false,
        script: timerScript,
        'script-props': ['format'],
        traits: [
          {
            label: 'Format',
            name: 'format',
            changeProp: true,
            type: 'select',
            options: [
              { id: 'format1', name: 'DD/MM/YYYY HH:MM:ss' },
              { id: 'format2', name: 'YYYY-MM-DD HH:MM:ss' },
            ],
          },
        ],
        components: `
          <span data-js="timerblock" class="${pfx}-cont">
          </span>
        `,
        styles: (style || '') + options?.styleAdditional,
        ...props,
      },
    },
  });
};

export default plugin;
