import React, { FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Box, Button, TextField } from '@mui/material';

import { CreateSlideResponse, useUpdateSlide } from '../../api-http/slides';
import { AlertBar } from '../../components/AlertBar';
import { formMsg, notifyMsg } from '../../configs';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../api-http/apiService';
import { ERROR, SUCCESS } from '../../util/constant';

interface RenameSlideFormProps {
  onFinished: () => void;
  slide: CreateSlideResponse;
}

const validationSchema = yup.object({
  title: yup.string().required(formMsg.TITLE_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const RenameSlideForm: FC<RenameSlideFormProps> = ({
  onFinished,
  slide,
}) => {
  const { isLoading, error, data, mutate, isSuccess, isError } =
    useUpdateSlide();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?._id || isSuccess) {
      onFinished();
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.SLIDE_NAME_UPDATE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      title: slide?.title || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (slide?._id) {
        mutate({
          id: slide._id,
          title: values.title,
        });
      }
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="title"
          name="title"
          label="Slide Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <Box mt={'10px'} />
        <Button
          color="primary"
          disabled={!formik.values.title || isLoading}
          variant="contained"
          fullWidth
          type="submit"
        >
          Update
        </Button>
      </form>
    </Container>
  );
};
