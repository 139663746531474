import React, { FC, useEffect, useRef, useState } from 'react';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Input } from '@mui/material';
import { styled } from '@mui/system';

import { Nullable } from '../types';
import { AlertBar } from './AlertBar';
import { DynamicKeyValue } from '../types/slide.types';
import { CreateSlideResponse, useUpdateSlide } from '../api-http/slides';
import { notifyMsg } from '../configs';
import { useSnack } from '../util/useSnack';

const Row = styled('div')`
  margin-top: 4px;
`;

const InputKey = styled(Input)`
  margin-right: 10px;
  border-bottom-style: none;

  ${(props) =>
    props.disabled &&
    `&::before {border-bottom-style: none !important;}
  `}
  input {
    text-align: right;
  }
`;

const AddNewField = styled(Button)`
  float: right;
  margin-top: 6px !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
`;

const ScrollableContainer = styled('div')`
  height: 60vh;
  overflow-y: scroll;
`;

interface KeyValueFormProps {
  slide: CreateSlideResponse | undefined;
  onClose: () => void;
}

export const KeyValueForm: FC<KeyValueFormProps> = ({ slide, onClose }) => {
  const bottomRef = useRef<Nullable<HTMLDivElement>>(null);
  const { showSuccessSnack, showErrSnack } = useSnack();

  const htmlBodySortRegex = /(?:<body)(.*)(?=<\/body)/g;
  const dynamicSortRegex = /(\{).*?(\})/g;

  const [formFields, setFormFields] = useState<DynamicKeyValue[]>([]);
  const { isLoading, mutate, error, data } = useUpdateSlide({
    onSuccess() {
      showSuccessSnack(notifyMsg.DYNAMIC_VALUES_ADDED_SUCCESS);
      onClose();
    },
    onError: () => showErrSnack(notifyMsg.DYNAMIC_VALUES_ADDING_FAILED),
  });

  useEffect(() => {
    const items = JSON.stringify(slide?.projectData.elements)
      ?.match(htmlBodySortRegex)?.[0]
      ?.match(dynamicSortRegex);
    const dynamicData = slide?.dynamicData || [];
    items?.forEach((item: string) => {
      const key = item.replace('{', '').replace('}', '');
      if (!dynamicData.find((data: { key: string }) => data.key === key)) {
        dynamicData.push({ key, value: '' });
      }
    });
    const newDynamicData = dynamicData?.map((data: DynamicKeyValue) => {
      return { ...data, disabled: true };
    });
    setFormFields(newDynamicData);
  }, [slide]);

  useEffect(() => {
    if (data?._id) {
      onClose();
    }
  }, [data]);

  const handleFormChange = (
    eventName: string,
    value: string,
    index: number,
  ) => {
    const data = [...formFields];
    data[index][eventName as 'key' | 'value'] = value;
    setFormFields(data);
  };

  const submit = (e: unknown) => {
    (e as Event).preventDefault();
    const updatedDynamicData = formFields.filter((item) => !!item.key);
    mutate({
      id: slide?._id || '',
      dynamicData: updatedDynamicData,
    });
  };

  const addFields = async () => {
    setFormFields([...formFields, { key: '', value: '', disabled: false }]);
    return true;
  };

  const scrollToBottom = () => {
    bottomRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  const removeFields = (index: number) => {
    const data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const editField = (dynamicData: { index: number; disabled: boolean }) => {
    const data = [...formFields];
    data[dynamicData.index].disabled = dynamicData.disabled;
    setFormFields(data);
  };

  return (
    <form onSubmit={submit}>
      <AlertBar severity="error" msg={error?.msg} mb={2} />
      <ScrollableContainer>
        {formFields.map((form, index) => {
          return (
            <Row key={index}>
              <InputKey
                name="key"
                placeholder="Key"
                value={form.key}
                onChange={({ target }) =>
                  handleFormChange(target.name, target.value, index)
                }
                onDoubleClick={() => editField({ index, disabled: false })}
                onBlur={() => editField({ index, disabled: true })}
                disabled={form.disabled}
              />
              <Input
                name="value"
                placeholder="Value"
                value={form.value}
                onChange={({ target }) =>
                  handleFormChange(target.name, target.value, index)
                }
              />
              <Button
                onClick={() => removeFields(index)}
                variant="text"
                endIcon={<DeleteIcon />}
              />
            </Row>
          );
        })}
        <div ref={bottomRef} />
      </ScrollableContainer>
      <AddNewField
        onClick={() =>
          addFields().then((res) => {
            if (res) {
              scrollToBottom();
            }
          })
        }
        variant="text"
        endIcon={<ControlPointIcon />}
      >
        Add
      </AddNewField>
      <Box mb={4} />
      <Button
        color="primary"
        onClick={submit}
        variant="contained"
        fullWidth
        type="submit"
        disabled={isLoading}
      >
        Save
      </Button>
    </form>
  );
};

export default KeyValueForm;
