import React, { FC } from 'react';

import { Button } from '@mui/material';

import { DialogModal } from '../../components';
import { PageTitle } from '../../components/PageTitle';
import { metaTitle } from '../../util/tabTitle';
import { SlideForm } from './SlideForm';
import SlideTable from './SlidesTable';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import { FilterSelectWrapper } from '../../components/common/FilterSelectWrapper';

const SlidesPage: FC = () => {
  metaTitle('Slides');
  const [open, setOpen] = React.useState(false);

  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  return (
    <div>
      <PageTitle>Slides</PageTitle>
      <FilterSelectWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={isViewOnly}
        >
          Add new
        </Button>
      </FilterSelectWrapper>

      <SlideTable />

      <DialogModal title="New Slide" onClose={() => setOpen(false)} open={open}>
        <SlideForm onFinished={() => setOpen(false)} />
      </DialogModal>
    </div>
  );
};

export default SlidesPage;
