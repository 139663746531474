import { FC } from 'react';

import styled from 'styled-components';
import { Stage } from 'transition-hook';
import { AnimationEnum, DEFAULT_TRANS_TIME } from './constants';

import { getFitToWidthRatio } from '../web-editor/utils';
import SlidePreviewLarge from './SlidePreviewLarge';
import { LinearProgress } from '@mui/material';

// default transition time
const transition = `${DEFAULT_TRANS_TIME / 1000}s`;

const PreviewMainWraper = styled.div`
  overflow: hidden;
  background-color: black;
`;

const PreviewWrapper = styled.div<{
  ratio: number;
  width: string;
  height: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transform: ${({ ratio }) => `scale(${ratio}) translate(0, 0)`};
  transform-origin: left top;
`;

const getAnimation = (animation: string, stage: Stage) => {
  if (animation === AnimationEnum.fade) {
    return {
      transition,
      opacity: stage === 'enter' ? 1 : 0,
    };
  }
  if (animation === AnimationEnum.zoom) {
    return {
      transition,
      opacity: stage === 'enter' ? 1 : 0,
      transform: {
        from: `scale(2, 2) translate(-50%, -50%)`,
        enter: `scale(1) translate(0, 0)`,
        leave: `scale(1) translate(0, 0)`,
      }[stage],
    };
  }
  if (animation === AnimationEnum.slide) {
    return {
      transition,
      opacity: stage === 'enter' ? 1 : 0,
      transform: {
        from: `scale(1) translate(-100%, 0)`,
        enter: `scale(1) translate(0, 0)`,
        leave: `scale(1) translate(0, 0)`,
      }[stage],
    };
  }
  return {};
};

interface PresentationSlideProps {
  currentSlide: any;
  margin?: number;
  stage?: Stage;
}

const PresentationSlide: FC<PresentationSlideProps> = ({
  currentSlide,
  margin = 0,
  stage,
}) => {
  const slide = currentSlide?.slideData;

  if (!slide) {
    return <LinearProgress />;
  }

  const {
    projectData: {
      deviceInfo: { width, height },
    },
  } = slide;

  const { boxHeight, boxWidth, ratio } = getFitToWidthRatio({
    originalHeight: parseInt(height),
    originalWidth: parseInt(width),
    margin,
  });

  return (
    <PreviewMainWraper
      style={{
        width: boxWidth,
        maxHeight: boxHeight,
        position: 'absolute',
        ...(stage && getAnimation(currentSlide.animation, stage)),
      }}
    >
      <PreviewWrapper ratio={ratio} width={width} height={height}>
        <SlidePreviewLarge
          projectData={slide.projectData}
          dynamicData={slide.dynamicData}
        />
      </PreviewWrapper>
    </PreviewMainWraper>
  );
};

export default PresentationSlide;
