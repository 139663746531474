import { FC, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, TextField } from '@mui/material';

import { UserTable } from './UserTable';
import { Nullable } from '../../../types';
import { metaTitle } from '../../../util/tabTitle';
import { UserRole } from '../../../types/user.types';
import { useAuth } from '../../../context/auth-context';
import { AlertBar } from '../../../components/AlertBar';
import { PageTitle } from '../../../components/PageTitle';
import { useGetAdminUsers } from '../../../api-http/superAdmin';
import { StyleAutocomplete } from '../../../components/AutoComplete';
import { AddUserModal } from './components/AddUserModal';

const TopContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserPage: FC = () => {
  metaTitle('Super Admin - Users');

  const params = useParams<{ id: string }>();
  const { currentUser } = useAuth();
  const { data: adminUsers = [], error, refetch } = useGetAdminUsers();

  const [openUserModal, setOpenUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<Nullable<string>>(null);

  if (currentUser && currentUser.role !== UserRole.superAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageTitle>Users Page (Super Admin)</PageTitle>
      <TopContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenUserModal(true)}
        >
          Add user
        </Button>

        <StyleAutocomplete
          options={adminUsers.filter(
            (user) => params?.id === user?.organization?._id,
          )}
          getOptionLabel={(option) => `${option.name}`}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Search User" />
          )}
          style={{ marginLeft: 10 }}
          onChange={(_event, option) => setSelectedUserId(option?._id || null)}
        />
      </TopContainer>

      <AlertBar severity="error" msg={error?.msg} />

      <UserTable
        selectedOrgId={params?.id || ''}
        selectedUserId={selectedUserId}
      />

      <AddUserModal
        onClose={() => setOpenUserModal(false)}
        open={openUserModal}
        onFinished={() => refetch()}
      />
    </>
  );
};

export default UserPage;
