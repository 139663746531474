import { FC, MouseEvent, useEffect, useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { ChevronDown, Edit, Trash } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import {
  CreatePresentationResponse,
  useCreatePresentation,
  useDeletePresentation,
  useGetPaginatePresentation,
} from '../../api-http/presentations';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import { confirmationMsgs, notifyMsg } from '../../configs';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { PresentationActionMenu } from './PresentationActionMenu';
import { RenamePresentationForm } from './RenamePresentationForm';
import { useAuth } from '../../context/auth-context';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';

interface PresentationTableProp {
  selectedCategory: string;
}

export const PresentationTable: FC<PresentationTableProp> = ({
  selectedCategory,
}) => {
  const confirm = useConfirm();
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { switchedOrgId } = useAuth();
  const { sortOnClick, pagingParams, tableProps, setPage } = useTable();
  const { sortBy, order } = pagingParams;

  const disableEditOrDelete = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
  ]);
  const isSuperAdmin = useIsUserRole([UserRole.superAdmin]);

  const [renamePresentationModal, setRenamePresentationModal] = useState(false);
  const [activePresentation, setActivePresentation] = useState<
    CreatePresentationResponse | undefined
  >();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    data: list,
    isLoading,
    error,
    refetch,
  } = useGetPaginatePresentation({
    ...pagingParams,
    category: selectedCategory,
  });

  const { mutate: createPresentation, error: errorPresentation } =
    useCreatePresentation({
      onSuccess: () => {
        refetch();
        showSuccessSnack(notifyMsg.PRESENTATION_CLONE_SUCCESS);
      },
      onError: (err) => showErrSnack(err),
    });

  const {
    isLoading: isDeleteLoading,
    error: errorDelete,
    mutate: deletePresentation,
  } = useDeletePresentation({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.PRESENTATION_DELETE_SUCCESS);
    },
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  useEffect(() => {
    if (selectedCategory) {
      setPage(0);
    }
  }, [selectedCategory]);

  useEffect(() => {
    refetch();
  }, [switchedOrgId]);

  const handleMenu = (
    event: MouseEvent<HTMLButtonElement>,
    presentation: CreatePresentationResponse,
  ) => {
    setActivePresentation(presentation);
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: confirmationMsgs.PRESENTATION_DELETE,
        confirmationText: 'Yes',
      });
      deletePresentation({ id });
    } catch (error) {}
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clone = () => {
    createPresentation({
      ...(isSuperAdmin && switchedOrgId && { organizationId: switchedOrgId }),
      status: activePresentation?.status || true,
      slides: activePresentation?.slides || [],
      name: `${activePresentation?.name}_clone`,
      category: activePresentation?.category?._id || '',
    });
    handleMenuClose();
  };

  return (
    <>
      {(isLoading || isDeleteLoading) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg} />
      <AlertBar severity="error" msg={errorDelete?.msg} />
      <AlertBar severity="error" msg={errorPresentation?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === SortEnum.title || true}
                  direction={sortBy === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  Presentation Title
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Updated</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === SortEnum.createdAt || true}
                  direction={
                    sortBy === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data?.map((presentation) => (
              <TableRow
                key={presentation._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <NavLink to={`/presentations-editor/${presentation._id}`}>
                    <Edit size={12} /> {presentation.name}
                  </NavLink>
                </TableCell>
                <TableCell align="right">
                  {presentation.category?.name}
                </TableCell>
                <TableCell align="right">
                  {displayDistance(presentation.updatedAt)}
                </TableCell>
                <TableCell align="right">
                  {displayDistance(presentation.createdAt)}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={(event) => handleMenu(event, presentation)}
                    disabled={disableEditOrDelete}
                  >
                    <ChevronDown size={16} />
                  </Button>
                  <Button
                    onClick={() => handleDelete(presentation._id)}
                    disabled={disableEditOrDelete}
                  >
                    <Trash size={16} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <PresentationActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClickRename={() => {
          setRenamePresentationModal(true);
          handleMenuClose();
        }}
        onClickClone={clone}
      />

      <DialogModal
        title={'Edit Presentation'}
        onClose={() => setRenamePresentationModal(false)}
        open={renamePresentationModal}
      >
        <RenamePresentationForm
          onFinished={() => {
            refetch();
            setRenamePresentationModal(false);
          }}
          presentation={list && activePresentation}
        />
      </DialogModal>
    </>
  );
};
