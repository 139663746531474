import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { ApiServiceErr, axiosApi, MutOptions, QueryOpt } from './apiService';
import { PaginateSearchOptions } from '../types/pagination.types';
import { BaseOrganization } from '../types/organization.types';

type CreateOrgBody = Pick<BaseOrganization, 'name' | 'email'>;

export type CreateOrgResponse = Pick<
  BaseOrganization,
  'name' | 'email' | '_id' | 'createdAt' | 'updatedAt'
>;

export interface UpdateOrgBody
  extends Pick<BaseOrganization, 'name' | 'email'> {
  id: string;
}

interface UpdateOrgStatus {
  id: string;
  status: boolean;
}

export const useCreateOrg = (opt?: MutOptions<CreateOrgResponse>) =>
  useMutation<CreateOrgResponse, ApiServiceErr, CreateOrgBody>(async (data) => {
    const response = await axiosApi.post('/organizations/create', data);
    return response.data;
  }, opt);

export const useGetAllOrganizations = () =>
  useQuery<BaseOrganization[], ApiServiceErr>(
    ['organization/all'],
    async () => {
      const response = await axiosApi.get('/organizations/all');
      return response.data;
    },
  );

export const useUpdateOrg = (opt?: MutOptions<BaseOrganization>) =>
  useMutation<BaseOrganization, ApiServiceErr, UpdateOrgBody>(async (data) => {
    const response = await axiosApi.put('/organizations/update', data);
    return response.data;
  }, opt);

export const useUpdateOrgStatus = (opt?: MutOptions<BaseOrganization>) =>
  useMutation<BaseOrganization, ApiServiceErr, UpdateOrgStatus>(
    async (data) => {
      const response = await axiosApi.put('/organizations/status', {
        id: data.id,
        status: data.status,
      });
      return response.data;
    },
    opt,
  );

export const useGetOrganizations = (
  { limit, page, search, sortBy, order }: PaginateSearchOptions,
  opt: QueryOpt<PagingResponse<BaseOrganization[]>>,
) =>
  useQuery<PagingResponse<BaseOrganization[]>, ApiServiceErr>(
    [
      '/organizations/paginate/organizations?limit=${limit}&page=${page}&search=${search?.toString()}&sortby=${sortBy}&order=${order}',
      limit,
      page,
      search,
      sortBy,
      order,
    ],
    async () => {
      const response = await axiosApi.get(
        `/organizations/paginate/organizations?limit=${limit}&page=${page}&search=${search?.toString()}&sortby=${sortBy}&order=${order}`,
      );
      return response.data;
    },
    opt,
  );
