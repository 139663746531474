import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import styled from 'styled-components';
import { AlertBar } from '../../components/AlertBar';
import { useUserUpdate } from '../../api-http/users';
import { BaseUserWithOrg } from '../../types/user.types';
import { formMsg, notifyMsg } from '../../configs';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../api-http/apiService';
import { ERROR, SUCCESS } from '../../util/constant';

interface PresentationFormProps {
  onFinished: () => void;
  user: BaseUserWithOrg | undefined;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.NAME_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const UserUpdateForm: FC<PresentationFormProps> = ({
  onFinished,
  user,
}) => {
  const { isLoading, error, data, mutate, isSuccess, isError } =
    useUserUpdate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?._id) {
      onFinished();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.USER_UPDATE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        id: user?._id || '',
        name: values.name,
      });
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="User Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <Box mt={'10px'} />
        <Button
          color="primary"
          disabled={!formik.values.name || isLoading}
          variant="contained"
          fullWidth
          type="submit"
        >
          Update
        </Button>
      </form>
    </Container>
  );
};
