export interface PaginateOptions {
  limit: number;
  page: number;
  sortBy: string;
  order: Order;
}

export interface CategoryOptions {
  _id: string;
  name: string;
  createAt: string;
  updatedAt: string;
}

export interface PaginateSearchOptions extends PaginateOptions {
  search: string;
}
export interface PaginateCategoryOptions extends PaginateOptions {
  category: string;
}

export enum OrderEnum {
  asc = 'asc',
  desc = 'desc',
}

export enum SortEnum {
  name = 'name',
  title = 'title',
  createdAt = 'createdAt',
}

export type Order = OrderEnum.asc | OrderEnum.desc;
