import { AssetsResponse } from '../types/assets.types';
import { getStaticFileUrl } from './getStaticFileUrl';

export const tranformAssetsForConfig = (assets: AssetsResponse[]) =>
  assets
    .filter((asset) => asset.fileType?.includes('image'))
    .sort((a, b) =>
      a.originalName.localeCompare(b.originalName, 'en', {
        sensitivity: 'base',
      }),
    )
    .map((asset) => ({
      src: getStaticFileUrl(asset.path),
      name: `${asset.originalName}`,
    }));
