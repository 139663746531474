import { Autocomplete, FormControl, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useGetAllOrganizations } from '../api-http/organizations';
import { useAuth } from '../context/auth-context';
import { BaseOrganization } from '../types/organization.types';
import { UserRole } from '../types/user.types';
import { useIsUserRole } from '../util/useIsUserRole';

export const moveCurrentOrganizationToFirst = (
  organizations: BaseOrganization[],
  currentOrgId: string,
) => {
  return organizations
    .filter((org) => org._id === currentOrgId)
    .concat(organizations.filter((org) => org._id !== currentOrgId));
};

export const getOrganizationById = (
  organizations: BaseOrganization[],
  organizationId: string | null,
) => {
  const found =
    organizations.find((org) => org._id === organizationId) || organizations[0];
  return found
    ? {
        label: found.name,
        value: found._id,
      }
    : null;
};

const OrganizationSwitch: FC = () => {
  const isSuperAdmin = useIsUserRole([UserRole.superAdmin]);
  const { setSwitchedOrgId, currentUser, switchedOrgId } = useAuth();
  const { data: organizations } = useGetAllOrganizations();
  const [filteredOrganizations, setFilteredOrganizations] = useState(
    organizations || [],
  );

  useEffect(() => {
    if (organizations && currentUser?.organization) {
      setFilteredOrganizations(
        moveCurrentOrganizationToFirst(
          organizations,
          currentUser.organization._id,
        ),
      );
    }
  }, [organizations, currentUser?.organization]);

  if (!isSuperAdmin || !filteredOrganizations?.length) {
    return null;
  }
  const defaultValue = getOrganizationById(
    filteredOrganizations,
    switchedOrgId,
  );
  return (
    <FormControl
      sx={{
        '& > :not(style)': {
          m: 1,
          minWidth: 240,
          paddingLeft: '10px',
          paddingBottom: '2px',
          fontSize: '14px',
        },
      }}
    >
      <Autocomplete
        disablePortal
        id="organization-selector"
        options={filteredOrganizations.map(({ name: label, _id: value }) => ({
          label,
          value,
        }))}
        value={defaultValue}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} size="small" />}
        onChange={(_e, option) => {
          if (option) {
            setSwitchedOrgId(option.value);
          }
        }}
        clearIcon={false}
      />
    </FormControl>
  );
};

export default OrganizationSwitch;
