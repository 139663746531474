/**
 * Tutorials
 * - https://laracasts.com/discuss/channels/general-discussion/custom-components-grapesjs
 */
import grapesjs from 'grapesjs';
import {
  changeZoomLevely,
  injectEditorOverFlowFix,
  resizeToFitToWidth,
} from '../utils';

export const getAppToolBar = (editor: grapesjs.Editor) => {
  editor.Panels.addButton('options', {
    id: 'undo',
    className: 'fa fa-undo',
    attributes: { title: 'Undo' },
    command: () => {
      editor.runCommand('core:undo');
    },
  } as unknown as grapesjs.ButtonOptions);

  editor.Panels.addButton('options', {
    id: 'redo',
    className: 'fa fa-repeat',
    attributes: { title: 'Redo' },
    command: () => {
      editor.runCommand('core:redo');
    },
  } as unknown as grapesjs.ButtonOptions);

  editor.Panels.addButton('options', {
    id: 'clear-all',
    className: 'fa fa-trash icon-blank',
    attributes: { title: 'Clear canvas' },
    command: {
      run: () => {
        const modal = editor.Modal;

        const container = document.createElement('div');

        const clearAllBtn = document.createElement('button');
        clearAllBtn.setAttribute(
          'style',
          'margin-left: 75px; color: white; background-color: #2f65cb; border: none; padding: 5px 10px; border-radius: 3px;',
        );
        clearAllBtn.innerHTML = 'Clear All';
        clearAllBtn.onclick = () => {
          editor.DomComponents.clear();
          editor.CssComposer.clear();
          injectEditorOverFlowFix(editor);
          modal.close();
        };

        const cancelBtn = document.createElement('button');
        cancelBtn.setAttribute(
          'style',
          'margin-left: 90px; color: white; background-color: #2f65cb; border: none; padding: 5px 15px; border-radius: 3px;',
        );
        cancelBtn.innerHTML = 'Cancel';
        cancelBtn.onclick = () => {
          modal.close();
        };

        container.appendChild(clearAllBtn);
        container.appendChild(cancelBtn);

        modal.open({
          title: 'Do you want to get rid of all the canvases?',
          content: container,
          attributes: {
            class: 'canvas-clear-modal',
          },
        });
      },
    },
  } as unknown as grapesjs.ButtonOptions);

  editor.Panels.addButton('options', {
    id: 'zoom-out',
    className: 'fa fa-search-minus ',
    attributes: { title: 'Zoom Out' },
    command: {
      run: () => {
        changeZoomLevely(editor, -5);
      },
    },
  } as unknown as grapesjs.ButtonOptions);

  editor.Panels.addButton('options', {
    id: 'zoom',
    label: 'Fit to Width',
    attributes: { title: 'Reset Zoom' },
    command: {
      run: () => {
        resizeToFitToWidth(editor);
      },
    },
  } as unknown as grapesjs.ButtonOptions);

  editor.Panels.addButton('options', {
    id: 'zoom-in',
    className: 'fa fa-search-plus ',
    attributes: { title: 'Zoom in' },
    command: {
      run: () => {
        changeZoomLevely(editor, 5);
      },
    },
  } as unknown as grapesjs.ButtonOptions);
};
