export const DEFAULT_TIME_OUT = 3 * 1000; // 3 seconds
export const DEFAULT_TRANS_TIME = 2000; // 2 seconds
export enum AnimationEnum {
  none = 'none',
  fade = 'fade',
  slide = 'slide',
  zoom = 'zoom',
}

export const TRANSITIONS = [
  AnimationEnum.none,
  AnimationEnum.fade,
  AnimationEnum.slide,
  AnimationEnum.zoom,
];
