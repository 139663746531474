import * as React from 'react';
import { FC } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

interface UserActionMenuProps {
  onClickRename: () => void;
  onClickResetPassword: () => void;
  onClickDelete: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const UserActionMenu: FC<UserActionMenuProps> = ({
  onClickRename,
  onClickResetPassword,
  onClickDelete,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem>
        <Button onClick={onClickRename}>Rename User</Button>
      </MenuItem>
      <MenuItem>
        <Button onClick={onClickResetPassword}>Reset Password</Button>
      </MenuItem>
      <MenuItem>
        <Button onClick={onClickDelete}>Delete</Button>
      </MenuItem>
    </Menu>
  );
};
