import React, { FC, useState } from 'react';

import { Edit2, FileText, Trash2 } from 'react-feather';
import styled from 'styled-components';

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { SlideWithOptions } from '../../api-http/presentations';
import { DialogModal, SortableList } from '../../components';
import SlidePreview from '../../components/preview/SlidePreview';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import { TransitionSelectProps } from './PresentationEditorPage';
import KeyValueForm from '../../components/KeyValueForm';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  gap: 20px;
`;

interface PreviewListProp {
  slides: SlideWithOptions[];
  onSlideRemove: (index: number) => void;
  onSlideOrderChange: (index: any) => void;
  setSlides: (index: any) => void;
  durationOnChange: (data: {
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    index: number;
    slideId: string;
    animation: string;
  }) => void;
  transitionOnChange: (data: TransitionSelectProps) => void;
  initialTransitions: Array<string>;
  refetchPresentation: () => void;
}

interface SlideCardProp extends Omit<PreviewListProp, 'slides'> {
  slide: SlideWithOptions;
  isViewOnly: boolean;
  index: number;
}

const SlideCard = ({
  slide,
  index,
  isViewOnly,
  onSlideRemove,
  durationOnChange,
  transitionOnChange,
  initialTransitions,
  refetchPresentation,
}: SlideCardProp) => {
  const { slideData } = slide;

  const [editDynamicDataModal, setEditDynamicDataModal] = useState(false);

  if (!slideData) {
    return null;
  }

  const { projectData, dynamicData } = slideData;
  const hasDynamicAccess = useIsUserRole([
    UserRole.superAdmin,
    UserRole.orgAdmin,
    UserRole.dataEditor,
  ]);

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
        }}
      >
        <SlidePreview projectData={projectData} dynamicData={dynamicData} />
      </div>

      <div
        style={{
          width: '200px',
          overflow: 'hidden',
        }}
      >
        <Button disabled={isViewOnly}>
          <Link
            href={`/slides-editor/${slide.slideId}?goBackUrl=${window.location.pathname}`}
            variant="body2"
            underline="always"
          >
            <label style={{ display: 'flex', cursor: 'pointer' }}>
              {slideData.title}

              <Edit2
                width={'15px'}
                style={{ marginLeft: '5px', paddingBottom: '3px' }}
              />
            </label>
          </Link>
        </Button>
        <FormControl
          variant="standard"
          sx={{ minWidth: '100%', paddingBottom: '10px' }}
        >
          <TextField
            style={{ width: '90%' }}
            id="duration"
            name="duration"
            label="Duration (sec)"
            type="number"
            defaultValue={slide?.duration / 1000}
            onChange={(event) =>
              durationOnChange({
                event,
                index,
                slideId: slideData._id,
                animation: slide.animation,
              })
            }
            disabled={isViewOnly}
            variant="standard"
          />
        </FormControl>

        <FormControl variant="standard" sx={{ minWidth: '90%' }}>
          <InputLabel id="transition-select-label">Transition</InputLabel>
          <Select
            labelId="transition-select-label"
            id="transition-select"
            value={slide?.animation}
            onChange={(event) => {
              transitionOnChange({
                index,
                slideId: slideData._id,
                slideData: slideData._id,
                duration: slide?.duration,
                animation: event.target.value,
              });
            }}
            label="Transition"
            disabled={isViewOnly}
          >
            <MenuItem value="" />
            {initialTransitions.map((row) => (
              <MenuItem key={row} value={row}>
                {row}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <IconButton
        size={'medium'}
        onClick={() => setEditDynamicDataModal(true)}
        disabled={!hasDynamicAccess}
      >
        <FileText
          width={'20px'}
          style={{ marginLeft: '5px' }}
          color={!hasDynamicAccess ? 'gray' : '#4782da'}
        />
      </IconButton>
      <IconButton
        size="medium"
        onClick={() => onSlideRemove(index)}
        disabled={isViewOnly}
      >
        <Trash2 width={18} color={isViewOnly ? 'gray' : 'red'} />
      </IconButton>

      <DialogModal
        title={'Edit Dynamic Values'}
        onClose={() => setEditDynamicDataModal(false)}
        open={editDynamicDataModal}
      >
        <KeyValueForm
          onClose={() => {
            setEditDynamicDataModal(false);
            refetchPresentation();
          }}
          slide={slideData}
        />
      </DialogModal>
    </>
  );
};

export const PreviewList: FC<PreviewListProp> = ({
  slides,
  refetchPresentation,
  ...props
}) => {
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  return (
    <Container>
      <SortableList
        items={slides}
        onChange={(data) => {
          props.setSlides(data);
          const slides = [];
          for (const slide of data) {
            const { animation, slideId, duration } = slide;
            slides.push({
              animation,
              duration,
              slideId,
              slideData: slideId,
            });
          }
          props.onSlideOrderChange(slides);
        }}
        renderItem={(item) => (
          <SortableList.Item id={item.slideId}>
            <SortableList.DragHandle />
            <SlideCard
              key={item.slideId}
              {...props}
              slide={item}
              index={item.id - 1}
              isViewOnly={Boolean(isViewOnly)}
              refetchPresentation={refetchPresentation}
            />
          </SortableList.Item>
        )}
      />
    </Container>
  );
};
