import { FC } from 'react';

import { useParams } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import { useGetPresentation } from '../../api-http/presentations';
import { AlertBar } from '../../components/AlertBar';
import FullScreenMode from '../../components/FullScreenMode';
import { metaTitle } from '../../util/tabTitle';
import PresentationPreview from '../../components/preview/PresentationPreview';

const PresentationPreviewPage: FC = () => {
  metaTitle('Preview');

  const params = useParams<{ id: string }>();
  const { data, error, isLoading } = useGetPresentation(params.id || '');

  if (error) {
    return <AlertBar severity="error" msg={error?.msg} />;
  }
  if (isLoading) {
    return <LinearProgress />;
  }
  if (!data) {
    return null;
  }

  return (
    <FullScreenMode
      backUrl={`/presentations-editor/${params.id}`}
      hideFullScreenBtn
    >
      <PresentationPreview
        presentation={data}
        margin={window.innerWidth * 0.3}
      />
    </FullScreenMode>
  );
};

export default PresentationPreviewPage;
