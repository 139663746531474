import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { AssetsResponse } from '../types/assets.types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

export const useGetAssets = () =>
  useQuery<AssetsResponse[], ApiServiceErr>(['/assets'], async () => {
    const response = await axiosApi.get('/assets/all');
    return response.data;
  });

export const useDeleteAssets = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/assets/delete/${data.id}`);
    return response.data;
  }, opt);

export const useUploadAsset = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (formData) => {
    const response = await axiosApi.post(`/assets/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }, opt);

export const useGetPaginateAssets = ({
  limit,
  page,
  sortBy,
  order,
}: PaginateOptions) =>
  useQuery<PagingResponse<AssetsResponse>, ApiServiceErr>(
    [
      '/assets/paginate/assets?limit=${limit}&page=${page}&sortBy=${sortBy}&order=${order}',
    ],
    async () => {
      const response = await axiosApi.get(
        `/assets/paginate/assets?limit=${limit}&page=${page}&sortBy=${sortBy}&order=${order}`,
      );
      return response.data;
    },
  );
