const authMsg = {
  EMAIL_INVALID: 'Must be a valid email',
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  CURRENT_PASSWORD_REQUIRED: 'Current password is required',
  NEW_PASSWORD_REQUIRED: 'New password is required',
  PASSWORD_6CHAR_REQUIRED: 'Password must be at least 6 characters',
  PASSWORD_SPACES: 'Password cannot contain white space',
  PASSWORD_COMBINATION_REQUIRED:
    'Password must contain capital & simple letters, numbers and symbols',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
  PASSWORD_UNMATCHED: 'Passwords must match',
  EMAIL_PASSWORD_INCORRECT: 'Incorrect email or password',
  PIN_CODE_REQUIRED: 'Pin Code is required',
};

const errorMsg = {
  SOMETHING_WRONG: 'Something went wrong',
};

const formMsg = {
  NAME_REQUIRED: 'Name is required',
  ORGANIZATION_REQUIRED: 'Organization is required',
  TITLE_REQUIRED: 'Title is required',
  TITLE_3CHAR_REQUIRED: 'Title should be of minimum 3 characters length',
  PASSWORD_REQUIREMENTS_FAILED:
    'Password should be 8-16 long and must include at least one special character,numeric value,upper-case and a lower-case value ',
};

const notifyMsg = {
  ORGANIZATION_ADDED_SUCCESS: 'Organization successfully added',
  USER_ADDED_SUCCESS: 'User successfully added',
  ORGANIZATION_UPDATE_SUCCESS: 'Organization successfully updated',
  USER_DELETE_SUCCESS: 'User successfully deleted',
  DELETE_ATTEMPT_FAILED: 'Delete attempt failed',
  USER_UPDATE_SUCCESS: 'User details successfully updated',
  ASSET_ADDED_SUCCESS: 'Asset successfully added',
  ASSET_DELETE_SUCCESS: 'Asset successfully deleted',
  PRESENTATION_DELETE_SUCCESS: 'Presentation successfully deleted',
  PRESENTATION_CLONE_SUCCESS: 'Presentation successfully cloned',
  PRESENTATION_DETAILS_UPDATE_SUCCESS:
    'Presentation details successfully updated',
  PASSWORD_CHANGED_SUCCESS: 'Password changed successfully',
  PROFILE_DETAILS_UPDATE_SUCCESS: 'Profile details successfully updated',
  DYNAMIC_VALUES_ADDED_SUCCESS: 'Dynamic Values successfully added',
  DYNAMIC_VALUES_ADDING_FAILED: 'Dynamic values adding failed',
  SLIDE_NAME_UPDATE_SUCCESS: 'Slide name successfully updated',
  SLIDE_DELETE_SUCCESS: 'Slide successfully deleted',
  SLIDE_CLONE_SUCCESS: 'Slide successfully cloned',
  ORGANIZATION_ENABLE_SUCCESS: 'Organization successfully enabled',
  ORGANIZATION_DISABLE_SUCCESS: 'Organization successfully disabled',
  CATEGORY_DELETE_SUCCESS: 'Category successfully deleted',
  CATEGORY_CREATE_SUCCESS: 'Category successfully created',
  CATEGORY_CREATE_FAILED: 'Category create attempt failed',
  CATEGORY_RENAME_SUCCESS: 'Category name successfully renamed',
  CATEGORY_RENAME_FAILED: 'Category rename attempt failed',
  DELETE_CONFIRMATION: 'Are you sure you want to delete?',
  CLIP_BOARD_COPIED_SUCCESS: 'Source copied',
};

const confirmationMsgs = {
  PRESENTATION_DELETE: 'Are you sure you want to delete this presentation?',
};

export { authMsg, errorMsg, formMsg, notifyMsg, confirmationMsgs };
