import React, { FC, useState } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, TextField } from '@mui/material';

import { StyleAutocomplete } from '../../components/AutoComplete';
import { SlideBody } from '../../types/slide.types';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import AddIcon from '@mui/icons-material/Add';

const Title = styled('p')`
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const SubTitle = styled('div')`
  justify-content: flex-start;
  align-items: center;
`;

interface IPresentationEditorAddSlides {
  id: string;
  slides: SlideBody[];
  slideOnChange: (id: string) => void;
}

const PresentationEditorAddSlides: FC<IPresentationEditorAddSlides> = ({
  id,
  slideOnChange,
  slides,
}) => {
  const disableForm = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  const [selectedSlide, setSelectedSlide] = useState<SlideBody | null>();

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    slide: SlideBody | null | undefined,
  ) => {
    event.preventDefault();
    if (slide?._id) {
      slideOnChange(slide._id);
    }
    setSelectedSlide(null);
  };

  return (
    <>
      <div>
        <Title style={{ paddingLeft: '0.5%' }}>
          Add New Slide
          <Link
            to={`/presentation-preview/${id}`}
            style={{ textDecoration: 'none' }}
          >
            <Button color="success" variant="contained">
              Preview
            </Button>
          </Link>
        </Title>
        <SubTitle>
          <form onSubmit={(event) => handleSubmit(event, selectedSlide)}>
            <StyleAutocomplete
              options={slides || []}
              renderInput={(params) => <TextField {...params} label="Slide" />}
              getOptionLabel={(slide) => `${slide.title}`}
              sx={{ width: 300, margin: 2 }}
              onChange={(_event, value) => setSelectedSlide(value)}
              clearOnEscape
              openOnFocus
              clearOnBlur
              disabled={disableForm}
            />
            <Button
              sx={{ padding: '10px' }}
              color="success"
              variant="contained"
              disabled={!selectedSlide}
              type="submit"
            >
              <AddIcon />
            </Button>
          </form>
        </SubTitle>
      </div>
    </>
  );
};

export default PresentationEditorAddSlides;
