import * as React from 'react';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';

import { Edit } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { Button, LinearProgress, Switch, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import {
  useGetOrganizations,
  useUpdateOrgStatus,
} from '../../../api-http/organizations';
import { Nullable } from '../../../types';
import { AlertBar } from '../../../components/AlertBar';
import { useAuth } from '../../../context/auth-context';
import { OrderEnum, SortEnum } from '../../../types/pagination.types';
import { displayDate } from '../../../util/dateTime';
import { notifyMsg } from '../../../configs';
import { useSnack } from '../../../util/useSnack';
import { useTable } from '../../../util/useTable';
import { BaseOrganization } from '../../../types/organization.types';
import { UpdateOrganizationModal } from './components/UpdateOrganizationModal';

interface OrganizationsTableProp {
  selectedOrgName: Nullable<string>;
}

export const OrganizationsTable: FC<OrganizationsTableProp> = ({
  selectedOrgName,
}) => {
  const { currentUser } = useAuth();
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { sortOnClick, pagingParams, setPage, tableProps } = useTable();
  const { sortBy, order } = pagingParams;

  const [orgUpdateModal, setOrgUpdateModal] = useState(false);
  const [activeOrg, setActiveOrg] = useState<Nullable<BaseOrganization>>(null);

  useEffect(() => {
    if (selectedOrgName) {
      setPage(0);
    }
  }, [selectedOrgName]);

  const {
    data: paginateData,
    isLoading,
    error,
    refetch,
  } = useGetOrganizations(
    { ...pagingParams, search: selectedOrgName || '' },
    { onError: (err) => showErrSnack(err) },
  );

  const {
    mutate: updateStatus,
    isLoading: statusIsLoading,
    error: statusError,
  } = useUpdateOrgStatus({
    onSuccess(response) {
      showSuccessSnack(
        response.status
          ? notifyMsg.ORGANIZATION_ENABLE_SUCCESS
          : notifyMsg.ORGANIZATION_DISABLE_SUCCESS,
      );
      refetch();
    },
    onError: (err) => showErrSnack(err),
  });

  const onOrgUpdate = (organization: BaseOrganization) => {
    setActiveOrg(organization);
    setOrgUpdateModal(true);
  };

  const switchHandler = (event: ChangeEvent<HTMLInputElement>, orgId: string) =>
    updateStatus({ id: orgId || '', status: event.target.checked });

  return (
    <>
      {(statusIsLoading || isLoading) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg} />
      <AlertBar severity="error" msg={statusError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === SortEnum.name}
                  direction={sortBy === SortEnum.name ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.name)}
                >
                  <b>Organization Name</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <b>Email</b>
              </TableCell>
              <TableCell align="right">
                <b>Pin Code</b>
              </TableCell>
              <TableCell align="right">
                <b>Updated At</b>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === SortEnum.createdAt}
                  direction={
                    sortBy === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  <b>Created At</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginateData?.data.map((organization) => {
              return (
                <TableRow
                  key={organization._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <NavLink to={`/admin/users/${organization._id}`}>
                      {organization?.name}
                    </NavLink>
                  </TableCell>
                  <TableCell align="right">{organization?.email}</TableCell>
                  <TableCell align="right">{organization?.pinCode}</TableCell>
                  <TableCell align="right">
                    {displayDate(organization?.updatedAt)}
                  </TableCell>
                  <TableCell align="right">
                    {displayDate(organization?.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => onOrgUpdate(organization)}>
                      <Edit size={16} />
                    </Button>
                    <Switch
                      id={organization?._id}
                      disabled={
                        currentUser?.organization?._id == organization?._id
                      }
                      checked={organization?.status}
                      onChange={(event) =>
                        switchHandler(event, organization._id)
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={paginateData?.pagination.total === 0}
          count={paginateData?.pagination.total || 0}
        />
      </TableContainer>

      <UpdateOrganizationModal
        onClose={() => setOrgUpdateModal(false)}
        open={orgUpdateModal}
        onFinished={() => refetch()}
        organizationData={activeOrg}
      />
    </>
  );
};
