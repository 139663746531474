import { useState } from 'react';

import { Order, OrderEnum, SortEnum } from '../types/pagination.types';

/**
 * Depreciated.. Use useTable.ts
 */
export const useSort = () => {
  const [sortBy, setSortBy] = useState<string>(SortEnum.title);
  const [order, setOrder] = useState<Order>(OrderEnum.asc);

  const sortOnClick = (header: string) => {
    const isAsc = sortBy === header && order === OrderEnum.asc;
    setOrder(isAsc ? OrderEnum.desc : OrderEnum.asc);
    setSortBy(header);
  };

  return { sortOnClick, sortBy, order };
};
