import { styled } from '@mui/styles';
import React, { FC, useEffect, useState } from 'react';
import { DynamicKeyValue, ProjectData } from '../../types/slide.types';
import { getHtmlForPreview } from '../../util/getHtmlForPreview';

interface SlidePreviewProp {
  projectData: ProjectData;
  dynamicData: DynamicKeyValue[];
}

const Container = styled('div')({
  backgroundColor: 'white',
  overflow: 'hidden',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  border: '1px solid #ccc',
  borderBottom: 'none',
  transform: 'scale(0.12) translate(0, 0)',
  transformOrigin: 'left top',
});

const SlidePreview: FC<SlidePreviewProp> = ({
  projectData,
  dynamicData = [],
}) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const { css, elements } = projectData;
    setHtml(getHtmlForPreview({ css, elements, dynamicData, muteVideo: true }));
  }, [projectData, dynamicData]);

  return (
    <div
      style={{
        width: 250,
        maxHeight: 160,
        marginLeft: 15,
        position: 'relative',
      }}
    >
      <Container
        style={{
          width: projectData.deviceInfo?.width,
          height: projectData.deviceInfo?.height,
          marginTop: '15px',
        }}
      >
        <iframe
          style={{ width: '100%', height: '100%', borderWidth: 0 }}
          srcDoc={html}
        />
      </Container>
    </div>
  );
};

export default SlidePreview;
