import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import styled from 'styled-components';
import devices from '../../components/web-editor/assets/devices.json';
import { useNavigate } from 'react-router-dom';
import { useCreateSlide } from '../../api-http/slides';
import { AlertBar } from '../../components/AlertBar';
import { getDefaultProjectData } from '../../util/getDefaultProjectData';
import { formMsg } from '../../configs';
import { useIsUserRole } from '../../util/useIsUserRole';
import { UserRole } from '../../types/user.types';
import { useAuth } from '../../context/auth-context';

interface SlideFormProps {
  onFinished: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required(formMsg.TITLE_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const SlideForm: FC<SlideFormProps> = ({ onFinished }) => {
  const navigate = useNavigate();
  const { switchedOrgId } = useAuth();
  const { isLoading, mutate, error, data } = useCreateSlide();

  useEffect(() => {
    if (data?._id) {
      onFinished();
      navigate(`/slides-editor/${data?._id}`);
    }
  }, [data]);

  const isSuperAdmin = useIsUserRole([UserRole.superAdmin]);

  const formik = useFormik({
    initialValues: {
      title: '',
      deviceType: devices[0].id,
      status: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const deviceInfo =
        devices.find((d) => d.id == values.deviceType) || devices[0];
      const projectData = {
        name: values.title,
        used: 1,
        projectData: getDefaultProjectData(deviceInfo),
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { deviceType: _deviceType, ...rest } = values;
      mutate(
        isSuperAdmin && switchedOrgId
          ? {
              ...rest,
              projectData: projectData?.projectData,
              dynamicData: [],
            }
          : {
              ...rest,
              projectData: projectData?.projectData,
              dynamicData: [],
            },
      );
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <AlertBar severity="error" msg={error?.msg} mb={2} />
        <TextField
          fullWidth
          id="title"
          name="title"
          label="Slide Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          style={{ marginBottom: 10 }}
        />
        <FormControl sx={{ width: '100%', minWidth: 200 }}>
          <InputLabel id="role">Device Type</InputLabel>
          <Select
            fullWidth
            id="deviceType"
            name="deviceType"
            value={formik.values.deviceType}
            label="Device Type"
            title="Device Type"
            placeholder="Device Type"
            onChange={formik.handleChange}
            style={{ marginBottom: 10 }}
          >
            {devices.map((device) => (
              <MenuItem key={device.id} value={device.id}>
                {device.name} - ({device.type})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          color="primary"
          disabled={!formik.values.title || isLoading}
          variant="contained"
          fullWidth
          type="submit"
        >
          Create
        </Button>
      </form>
    </Container>
  );
};
