import { FC } from 'react';
import { notifyMsg } from '../../../../configs';
import { DialogModal } from '../../../../components';
import { useSnack } from '../../../../util/useSnack';
import { useUserRegister } from '../../../../api-http/users';
import { UserForm } from './UserForm';
import { useAuth } from '../../../../context/auth-context';
import { UserRole } from '../../../../types/user.types';

interface AddUserModalProps {
  onFinished: () => void;
  onClose: () => void;
  open: boolean;
}

export const AddUserModal: FC<AddUserModalProps> = ({
  onFinished,
  open,
  onClose,
  ...props
}) => {
  const { currentUser } = useAuth();
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { isLoading, mutate, error, reset } = useUserRegister({
    onSuccess() {
      showSuccessSnack(notifyMsg.USER_ADDED_SUCCESS);
      onFinished();
      closeModal();
    },
    onError: (err) => showErrSnack(err),
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <DialogModal title="Add User" onClose={closeModal} open={open}>
      <UserForm
        {...props}
        onSubmit={(data) => mutate(data)}
        isLoading={isLoading}
        error={error}
        currentUserRole={currentUser?.role || UserRole.user}
      />
    </DialogModal>
  );
};
