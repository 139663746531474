/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import type grapesjs from 'grapesjs';

const CATEGORY = 'Custom Blocks';

export type PluginOptions = {
  /**
   * The ID used to create the block and component
   * @default 'slider-text'
   */
  id?: string;

  /**
   * The label used for the block and the component.
   * @default 'slider-textBlock'
   */
  label?: string;

  /**
   * Object to extend the default block. Pass a falsy value to avoid adding the block.
   * @example
   * { label: 'slider-textBlock', category: 'Extra', ... }
   */
  block?: Partial<grapesjs.BlockOptions>;

  /**
   * Object to extend the default component properties.
   * @example
   * { name: 'slider-textBlock', droppable: false, ... }
   */
  props?: grapesjs.ComponentDefinition;

  /**
   * Custom CSS styles for the component. This will replace the default one.
   * @default ''
   */
  style?: string;

  /**
   * Additional CSS styles for the component. These will be appended to the default one.
   * @default ''
   */
  styleAdditional?: string;

  /**
   * slider-textBlock component class prefix.
   * @default 'slider-text'
   */
  classPrefix?: string;
};

const plugin: grapesjs.Plugin<PluginOptions> = (editor, opts = {}) => {
  const options: PluginOptions = {
    id: 'slider-text',
    label: 'Text with slider controls',
    block: {},
    props: {},
    style: '',
    styleAdditional: '',
    ...opts,
  };

  const { block, props, style } = options;
  const id = options.id!;
  const label = options.label!;

  // Create block
  if (block) {
    editor.Blocks.add(id, {
      media: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M18.5 4l1.16 4.35-.96.26c-.45-.87-.91-1.74-1.44-2.18C16.73 6 16.11 6 15.5 6H13v10.5c0 .5 0 1 .33 1.25.34.25 1 .25 1.67.25v1H9v-1c.67 0 1.33 0 1.67-.25.33-.25.33-.75.33-1.25V6H8.5c-.61 0-1.23 0-1.76.43-.53.44-.99 1.31-1.44 2.18l-.96-.26L5.5 4h13z"/>
              </svg>`,
      label,
      category: CATEGORY,
      select: true,
      content: { type: id },
      ...block,
    });
  }

  const deviceAttributes = editor.Devices.getSelected().attributes;
  const deviceWidth = parseInt(deviceAttributes.width);
  const deviceHeight = parseInt(deviceAttributes.height);

  editor.Components.addType(id, {
    model: {
      isComponent: true,
      defaults: {
        droppable: false,
        'script-props': ['vertical', 'horizontal'],
        traits: [
          {
            type: 'range',
            name: 'vertical',
            label: 'Top margin',
            min: 0,
            max: deviceHeight,
            changeProp: true,
          },
          {
            type: 'range',
            name: 'horizontal',
            label: 'Left margin',
            min: 0,
            max: deviceWidth,
            changeProp: true,
          },
        ],
        components: `
          <span data-js="slider-text">Insert your text here</span>
        `,
        styles: (style || '') + options?.styleAdditional,
        ...props,
      },
      init() {
        this.on('change', this.handleChange);
        this.handleChange();
      },
      handleChange() {
        const getSelected = editor?.getSelected();
        if (
          getSelected?.attributes?.type === 'slider-text' &&
          // @ts-ignore
          (getSelected?.changed?.horizontal !== undefined ||
            // @ts-ignore
            getSelected?.changed?.vertical !== undefined)
        ) {
          const currentStyles = getSelected?.getStyle();
          getSelected?.setStyle({
            ...currentStyles,
            'margin-top': `${this.attributes.vertical}px`,
            'margin-left': `${this.attributes.horizontal}px`,
          });
        }
      },
    },
  });
};

export default plugin;
