import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const StyleTextfield = styled(TextField)`
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  input {
    padding: 0;
  }
` as typeof TextField;
