import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import axios, { HttpStatusCode } from 'axios';

import { ADVERTEX_API_URL, errorMsg } from '../configs';
import { Nullable } from '../types';
import {
  getSwitchedOrgIdInSession,
  getTokenInLocal,
} from '../util/cacheStorage';

export interface ApiServiceErr {
  msg: string;
  status: HttpStatusCode;
}

export interface DeleteBody {
  id: string;
}

export type MutOptions<Response, TVariables = unknown> = UseMutationOptions<
  Response,
  ApiServiceErr,
  TVariables,
  unknown
>;

export type QueryOptions<Response, TVariables = unknown> = UseQueryOptions<
  Response,
  ApiServiceErr,
  TVariables,
  any[]
>;

export type QueryOpt<Response> = UseQueryOptions<
  Response,
  ApiServiceErr,
  any,
  any
>;

const getApiError = (error: any, defaultMessage?: string) => {
  if (typeof error === 'string') {
    return error;
  }
  if (error?.msg) {
    return error.msg;
  }
  if (error?.response?.data) {
    if (typeof error.response.data === 'string') {
      return error.response.data;
    }
    if (typeof error.response.data.message === 'string') {
      return error.response.data.message;
    }
    if (typeof error.response.data.error === 'string') {
      return error.response.data.error;
    }
  }

  return defaultMessage || errorMsg.SOMETHING_WRONG;
};

const getBearToken = (token: Nullable<string>) =>
  token ? `Bearer ${token}` : null;

const axiosApi = axios.create({ baseURL: ADVERTEX_API_URL });

// In here, we handle the api error
axiosApi.interceptors.response.use(undefined, (error) =>
  Promise.reject({
    msg: getApiError(error),
    status: error.response.status || 500,
  }),
);

axiosApi.interceptors.request.use((config: any) => {
  (config as any).headers.authorization = getBearToken(getTokenInLocal());
  (config as any).headers['switched-org-id'] =
    getSwitchedOrgIdInSession() || '';
  return config;
});

export { axiosApi, getApiError };
