import React, { FC, useEffect, useState } from 'react';
import { DynamicKeyValue, ProjectData } from '../../types/slide.types';
import { getHtmlForPreview } from '../../util/getHtmlForPreview';

interface SlidePreviewLargeProp {
  projectData: ProjectData;
  dynamicData: DynamicKeyValue[];
}

const SlidePreviewLarge: FC<SlidePreviewLargeProp> = ({
  projectData,
  dynamicData = [],
}) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const { css, elements } = projectData;
    setHtml(getHtmlForPreview({ css, elements, dynamicData }));
  }, [projectData, dynamicData]);

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        borderWidth: 0,
        position: 'absolute',
      }}
      srcDoc={html}
    />
  );
};

export default SlidePreviewLarge;
