import devices from '../components/web-editor/assets/devices.json';

export const getDefaultProjectData = (deviceInfo = devices[0]) => ({
  assets: [],
  styles: [],
  deviceInfo,
  pages: [
    {
      frames: [
        {
          component: {
            type: 'wrapper',
            stylable: [
              'background',
              'background-color',
              'background-image',
              'background-repeat',
              'background-attachment',
              'background-position',
              'background-size',
            ],
            attributes: {
              id: 'ig07',
            },
            _undoexc: ['status', 'open'],
          },
        },
      ],
      id: 'gU8k8RbCajsjAtlC',
    },
  ],
  elements: '<body id="ig07"></body>',
  css: '* { box-sizing: border-box; } body {margin: 0;}',
});
