import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import styled from 'styled-components';
import { AlertBar } from '../../components/AlertBar';
import { useUserChangePassword } from '../../api-http/users';
import { authMsg, notifyMsg } from '../../configs';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../api-http/apiService';
import { ERROR, SUCCESS } from '../../util/constant';

interface ProfileChangePasswordFormProps {
  onFinished: () => void;
}

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .max(255)
    .required(authMsg.CURRENT_PASSWORD_REQUIRED),
  newPassword: yup
    .string()
    .max(255)
    .required(authMsg.NEW_PASSWORD_REQUIRED)
    .min(6, authMsg.PASSWORD_6CHAR_REQUIRED)
    .matches(/^[^\s]+$/, authMsg.PASSWORD_SPACES)
    .test(
      'passwordRequirements',
      authMsg.PASSWORD_COMBINATION_REQUIRED,
      (value: any) =>
        [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
          pattern.test(value),
        ),
    ),
  confirmNewPassword: yup
    .string()
    .max(255)
    .required(authMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('newPassword')], authMsg.PASSWORD_UNMATCHED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const ProfileChangePasswordForm: FC<ProfileChangePasswordFormProps> = ({
  onFinished,
}) => {
  const { isLoading, error, isSuccess, mutate, isError } =
    useUserChangePassword();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      onFinished();
      enqueueSnackbar(notifyMsg.PASSWORD_CHANGED_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id={'currentPassword'}
          name={'currentPassword'}
          label={'Current Password'}
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.currentPassword &&
            Boolean(formik.errors.currentPassword)
          }
          helperText={
            formik.touched.currentPassword && formik.errors.currentPassword
          }
          style={{ marginBottom: 10 }}
          type={'password'}
        />

        <TextField
          fullWidth
          id={'newPassword'}
          name={'newPassword'}
          label={'New Password'}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          style={{ marginBottom: 10 }}
          type={'password'}
        />

        <TextField
          fullWidth
          id={'confirmNewPassword'}
          name={'confirmNewPassword'}
          label={'Confirm Password'}
          value={formik.values.confirmNewPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmNewPassword &&
            Boolean(formik.errors.confirmNewPassword)
          }
          helperText={
            formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword
          }
          style={{ marginBottom: 10 }}
          type={'password'}
        />

        <Box mt={'10px'} />
        <Button
          color={'primary'}
          disabled={isLoading}
          variant={'contained'}
          fullWidth
          type={'submit'}
        >
          Update
        </Button>
      </form>
    </Container>
  );
};
