import * as React from 'react';
import { FC, useEffect } from 'react';

import { useConfirm } from 'material-ui-confirm';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy, Download, Trash } from 'react-feather';

import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { useDeleteAssets, useGetPaginateAssets } from '../../api-http/assets';
import { AlertBar } from '../../components/AlertBar';
import { notifyMsg } from '../../configs';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { getStaticFileUrl } from '../../util/getStaticFileUrl';
import { useIsUserRole } from '../../util/useIsUserRole';
import { AssetWithPlaceholder } from './AssetWithPlaceholder';
import { useAuth } from '../../context/auth-context';
import { useTable } from '../../util/useTable';
import { useSnack } from '../../util/useSnack';

interface AssetsTableProp {
  refresh: boolean;
}

export const AssetsTable: FC<AssetsTableProp> = ({ refresh }) => {
  const confirm = useConfirm();
  const disableDelete = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  const { switchedOrgId } = useAuth();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortBy, order } = pagingParams;

  const {
    data: list,
    error: listError,
    isLoading: listIsLoading,
    refetch: listRefetch,
  } = useGetPaginateAssets(pagingParams);

  const {
    error,
    mutate: deleteAsset,
    data: deletedAsset,
    isLoading,
  } = useDeleteAssets({
    onSuccess: () => showSuccessSnack(notifyMsg.ASSET_DELETE_SUCCESS),
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  useEffect(() => {
    listRefetch();
  }, [deletedAsset, refresh, tableProps, switchedOrgId]);

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      deleteAsset({ id });
    } catch (e) {}
  };

  return (
    <>
      <AlertBar severity="error" msg={error?.msg || listError?.msg} />
      {(isLoading || listIsLoading) && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {' '}
                <TableSortLabel
                  active={sortBy === SortEnum.title || true}
                  direction={sortBy === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  Asset Name
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">Preview</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === SortEnum.createdAt || true}
                  direction={
                    sortBy === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Actions</TableCell>
              <TableCell align="right" width={90}>
                <Button disabled>
                  <Download size={16} />{' '}
                </Button>
              </TableCell>
              <TableCell align="right" width={90}>
                <Button disabled>
                  <Trash size={16} />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data?.map((asset) => {
              return (
                <TableRow
                  key={asset?._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {asset?.originalName} ({asset?.fileType.split('/')[0]})
                  </TableCell>
                  <TableCell align="left">
                    {asset.fileType.includes('image') && (
                      <AssetWithPlaceholder
                        src={getStaticFileUrl(asset?.thumbnailUrl)}
                        width="140px"
                        type="image"
                      />
                    )}
                    {asset.fileType.includes('video') && (
                      <AssetWithPlaceholder
                        src={getStaticFileUrl(asset?.path)}
                        width="140"
                        type="video"
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {displayDistance(asset?.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <CopyToClipboard
                      text={asset?.url}
                      onCopy={() =>
                        showSuccessSnack(notifyMsg.CLIP_BOARD_COPIED_SUCCESS)
                      }
                    >
                      <Button>
                        <Copy size={16} />
                      </Button>
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell align="right">
                    <a href={asset?.url} download>
                      <Button>
                        <Download size={16} />
                      </Button>
                    </a>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      disabled={disableDelete}
                      onClick={() => handleDelete(asset._id)}
                    >
                      <Trash size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>
    </>
  );
};
