import { useEffect, useState } from 'react';
import {
  PAGE,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_OPTION,
} from '../containers/constant';
import { useAuth } from '../context/auth-context';

import { Order, OrderEnum, SortEnum } from '../types/pagination.types';

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const useTable = () => {
  const [sortBy, setSortBy] = useState<string>(SortEnum.title);
  const [order, setOrder] = useState<Order>(OrderEnum.asc);
  const [page, setPage] = useState(PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const { switchedOrgId } = useAuth();

  useEffect(() => {
    setPage(0);
  }, [switchedOrgId]);

  const sortOnClick = (header: string) => {
    const isAsc = sortBy === header && order === OrderEnum.asc;
    setOrder(isAsc ? OrderEnum.desc : OrderEnum.asc);
    setSortBy(header);
  };

  return {
    sortOnClick,
    setPage,
    switchedOrgId,
    pagingParams: {
      sortBy,
      order,
      limit: rowsPerPage,
      page: page + 1,
    },
    tableProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTION,
      onPageChange: (_: unknown, p: number) => setPage(p),
      onRowsPerPageChange: (e: Event) => {
        setRowsPerPage(parseInt(`${e.target.value}`, 10));
        setPage(0);
      },
    },
  };
};
