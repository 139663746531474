import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';
import { DynamicKeyValue, ProjectData } from '../types/slide.types';

interface CreateSlideBody {
  title: string;
  status: boolean;
  projectData: any;
  dynamicData: DynamicKeyValue[];
}

export interface CreateSlideResponse {
  title: string;
  status: boolean;
  projectData: ProjectData;
  dynamicData: DynamicKeyValue[];
  organization: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  id: number;
}

interface UpdateSlideBody {
  id: string;
  title?: string;
  status?: boolean;
  projectData?: any;
  dynamicData?: DynamicKeyValue[];
}

export interface UpdateSlideResponse {
  _id: string;
}

export type GetSlidesResponses = CreateSlideResponse[];

export const useCreateSlide = (opt?: MutOptions<CreateSlideResponse>) =>
  useMutation<CreateSlideResponse, ApiServiceErr, CreateSlideBody>(
    async (data) => {
      const response = await axiosApi.post('/slides/create', data);
      return response.data;
    },
    opt,
  );

export const useUpdateSlide = (opt?: MutOptions<UpdateSlideResponse>) =>
  useMutation<UpdateSlideResponse, ApiServiceErr, UpdateSlideBody>(
    async (data) => {
      const response = await axiosApi.put('/slides/update', data);
      return response.data;
    },
    opt,
  );

export const useGetAllSlides = () =>
  useQuery<GetSlidesResponses, ApiServiceErr>(['slides/all'], async () => {
    const response = await axiosApi.get('/slides/all');
    return response.data;
  });

export const useGetSlideById = (id: string) =>
  useQuery<CreateSlideResponse, ApiServiceErr>(
    ['slides/{byId}', id],
    async ({ queryKey }) => {
      const response = await axiosApi.get(`/slides/${queryKey[1]}`);
      return response.data;
    },
  );

export const useDeleteSlide = (opt?: MutOptions<UpdateSlideResponse>) =>
  useMutation<UpdateSlideResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/slides/delete/${data.id}`);
    return response.data;
  }, opt);

export const useGetPaginateSlide = ({
  limit,
  page,
  sortBy,
  order,
}: PaginateOptions) =>
  useQuery<PagingResponse<GetSlidesResponses>, ApiServiceErr>(
    [
      '/slides/paginate/slides?limit=${limit}&page=${page}&sortBy=${sortBy}&asc=${asc}',
      limit,
      page,
      sortBy,
      order,
    ],
    async () => {
      const response = await axiosApi.get(
        `/slides/paginate/slides?limit=${limit}&page=${page}&sortby=${sortBy}&order=${order}`,
      );
      return response.data;
    },
  );
